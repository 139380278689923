import { Alert, Grid, Typography } from '@mui/material';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import { ProductLocation } from '../../../lib/api/products/enums/product-locations.enum';
import RhfSwitch from '../../ui/forms/RhfSwitch';
import RhfAutocomplete from '../../ui/forms/RhfAutocomplete';
import { useFormContext } from 'react-hook-form';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import { ViewProductDto } from '../../../lib/api/products/dto/view-product.dto';
import RhfIntegerInput from '../../ui/forms/RhfIntegerInput';

interface Props {
  product: ViewProductDto;
}

export default function UpdateProductFormStoreAvailabilityPartial({
  product,
}: Props) {
  const {
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    watch,
  } = useFormContext<CreateProductDto>();

  const isSellable = watch('isSellable');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Store Availability
        </Typography>
        {product.hasVariants && (
          <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
            This product has variants. Turning availability off here will
            override the availability values at the variant level. For example,
            if you set the product to not sellable, that will make all the
            variants of this product not sellable even if the variant is marked
            as sellable. The same would be true for turning off Sell to Popes,
            Sell to Premium, or Sell to Standard.
            <br />
            <br />
            On the other hand, if you mark the product as sellable, but the
            variant is marked as not sellable, then that particular variant will
            not be sellable.
          </Alert>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <RhfSwitch<CreateProductDto>
            label={['Live on Store', 'Not Sellable']}
            name="isSellable"
            control={control}
          />
        </Grid>

        {isSellable && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Popes"
                name="sellToPopes"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Premium"
                name="sellToPremium"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RhfSwitch<CreateProductDto>
                label="Sell to Standard"
                name="sellToStandard"
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <RhfAutocomplete
          control={control}
          name="location"
          defaultText="Select location"
          optionsAsEnum={ProductLocation}
        />
      </Grid>

      {!product.hasVariants && (
        <Grid item xs={6} sm={3}>
          <RhfIntegerInput
            control={control}
            name="orderLimit"
            label="Order Limit"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FromSubmitButton
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
          isValid={isValid}
          text="Update Product"
        />
      </Grid>
    </Grid>
  );
}
