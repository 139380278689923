import { Grid } from '@mui/material';
import { useState } from 'react';
import { ViewUserDto } from '../../lib/api/users/dto/view-user.dto';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CardItemTitle from '../ui/CardItemTitle';
import DeleteIconButton from '../ui/buttons/DeleteIconButton';
import EditIconButton from '../ui/buttons/EditIconButton';

interface Props {
  user: ViewUserDto;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export default function UserCard({ user, onDelete, onEdit }: Props) {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <Grid
      container
      spacing={0}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <Grid item xs={10}>
        <Grid container spacing={0}>
          <CardItemTitle
            text={`${user?.profile?.firstName} ${user?.profile?.lastName}`}
          />
          <Grid item xs={1}>
            <AlternateEmailIcon sx={{ color: 'info.main' }} />
          </Grid>
          <Grid item xs={11}>
            <span>{user.email}</span>
          </Grid>

          {user.profile.mobilePhone && (
            <>
              <Grid item xs={1}>
                <PhoneAndroidIcon sx={{ color: 'info.main' }} />
              </Grid>
              <Grid item xs={11}>
                <span>{user.profile.mobilePhone}</span>
              </Grid>
            </>
          )}

          {user.profile.officePhone && (
            <>
              <Grid item xs={1}>
                <PhoneIcon sx={{ color: 'info.main' }} />
              </Grid>
              <Grid item xs={11}>
                <span>{user.profile.officePhone}</span>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={2}
        hidden={!showDelete}
        sx={{
          textAlign: 'right',
        }}
      >
        <EditIconButton resource="User" onClick={() => onEdit?.(user.id)} />
        <DeleteIconButton resource="User" onClick={() => onDelete?.(user.id)} />
      </Grid>
    </Grid>
  );
}
