import { useCallback, useEffect, useMemo } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { StepperData } from './CustomStepper';
import { DtoStringKeysAsPath } from '../../../lib/util/type-utils';
import { VALIDATION_ERR_DELAY } from '../../../config/AppConstants';

interface Props<Dto> {
  resolver?: Resolver<Dto, any>;
  defaultValues?: any;
  steps: StepperData<Dto>[];
  setSteps: any;
}

type handleNextClickFn<Dto> = (
  fields: DtoStringKeysAsPath<Dto>[],
) => Promise<void>;

export default function useMultiStepForm<Dto>({
  resolver,
  steps,
  setSteps,
  defaultValues,
}: Props<Dto>) {
  const { setFocus, trigger, ...restUseFormProps } = useForm<Dto>({
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    resolver,
    defaultValues,
  });

  const setActivePage = (index: number) => {
    const stepToMarkActive = steps[index];
    if (!stepToMarkActive) return;

    //exit if no change
    const currentActiveStepIndex = steps.findIndex((s) => s.isActive);
    if (currentActiveStepIndex === index) return;

    setSteps((prev: StepperData<Dto>[]) => {
      return prev.map((s, loopIndex) => {
        if (s.isActive) {
          //Mark the currently active page as inactive
          return {
            ...s,
            isActive: false,
          };
        } else if (index === loopIndex) {
          //Mark the requested step as active
          return {
            ...s,
            isActive: true,
          };
        } else {
          return {
            ...s,
          };
        }
      });
    });
  };

  useEffect(() => {
    const activeStep = steps.find((s) => s.isActive);
    if (activeStep && activeStep.focusField) {
      // Is possible that we are trying to set focus on a field
      // control that hasn't been registered yet, for example if the
      // field we are setting control on is waiting on a network call
      // to complete before it is rendered. Let's handle that error here.
      setTimeout(() => {
        try {
          // @ts-ignore Path supports recursive mapping, DtoStringKeysAsPath only supports one level deep
          setFocus(activeStep.focusField);
        } catch (err) {
          console.log(
            `focus could not be set because control was not available.`,
          );
        }
      }, 250);
    }
  }, [setFocus, steps]);

  const handleNextClick = async () => {
    //1. Validate Current Step Fields
    const currentStepIndex = steps.findIndex((s) => s.isActive);

    if (currentStepIndex === -1) return;

    const triggerIsValid = await trigger(
      // @ts-ignore Path supports recursive mapping, DtoStringKeysAsPath only supports one level deep
      steps[currentStepIndex].fieldsToValidateOnNext,
    );

    //2. Mark Next Step as the Active Step
    if (triggerIsValid) {
      setActivePage(currentStepIndex + 1);
    }
  };

  const handleSkipClick: () => void = () => {
    const currentStepIndex = steps.findIndex((s) => s.isActive);
    if (currentStepIndex >= steps.length - 1) return;
    setActivePage(currentStepIndex + 1);
  };

  const handleBackClick: () => void = () => {
    const currentStepIndex = steps.findIndex((s) => s.isActive);
    if (currentStepIndex <= 0) return;
    setActivePage(currentStepIndex - 1);
  };

  return {
    handleNextClick,
    handleSkipClick,
    handleBackClick,
    setActivePage,
    setFocus,
    trigger,
    ...restUseFormProps,
  };
}
