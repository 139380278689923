import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BadgeIcon from '@mui/icons-material/Badge';
import { ViewShippingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-shipping-address-extended.dto';
import DeleteIconButton from '../../ui/buttons/DeleteIconButton';
import EditIconButton from '../../ui/buttons/EditIconButton';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import useModal from '../../ui/modal/useModal';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import AddOrUpdateContactForm from '../../contacts/AddOrUpdateContactForm';
import AddressContent from '../AddressContent';
import ContactList from '../../contacts/ContactList';
import { ViewBillingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-billing-address-extended.dto';
import { getAddressFirstLine } from '../address-utils';
import AddressTitle from '../AddressTitle';

interface Props {
  address: ViewBillingAddressExtendedDto | ViewShippingAddressExtendedDto;
  customerName: string;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export default function AddressListItem({
  address,
  customerName,
  onDelete,
  onEdit,
}: Props) {
  const [showDeleteAddress, setShowDeleteAddress] = useState(false);

  const {
    handleCloseModal: handleCloseAddContactModal,
    handleLaunchModal: handleLaunchAddContactModal,
    modalOpen: addContactModalOpen,
  } = useModal();

  const handleAddContact = () => {
    handleLaunchAddContactModal();
  };

  const onCreateContactSuccess = () => {
    handleCloseAddContactModal();
  };

  const addressShortName = getAddressFirstLine(address);

  return (
    <>
      <Stack
        spacing={2}
        onMouseEnter={() => setShowDeleteAddress(true)}
        onMouseLeave={() => setShowDeleteAddress(false)}
      >
        <Box display="flex" columnGap={2} rowGap={2}>
          <MapIcon sx={{ color: 'info.main' }} />
          <Box flexGrow={1}>
            <AddressTitle address={address} customerName={customerName} />
            <AddressContent address={address} />
          </Box>
          <Box hidden={!showDeleteAddress}>
            <EditIconButton
              resource="Address"
              onClick={() => onEdit?.(address.id)}
            />
            <DeleteIconButton
              resource="Address"
              onClick={() => onDelete?.(address.id)}
            />
          </Box>
        </Box>
        {address.type === AddressType.shipping && (
          <Box display="flex" columnGap={2}>
            <LocalShippingIcon sx={{ color: 'info.main' }} />
            <Typography component="p">
              {address?.route?.name || 'Not assigned'}
            </Typography>
          </Box>
        )}
      </Stack>

      <Box sx={{ mt: 2 }}>
        <Box display="flex" columnGap={2}>
          <BadgeIcon sx={{ color: 'info.main' }} />
          <Typography>Contacts</Typography>
        </Box>
        <ContactList
          contacts={address.contacts}
          addressShortName={addressShortName}
        />
        <Button onClick={handleAddContact} sx={{ ml: 4, mt: 2 }}>
          Add Contact
        </Button>
      </Box>

      <FullScreenModal
        title={`Create Contact for ${addressShortName}`}
        open={addContactModalOpen}
        onClose={handleCloseAddContactModal}
      >
        <AddOrUpdateContactForm
          addressId={address.id}
          onSuccess={onCreateContactSuccess}
        />
      </FullScreenModal>
    </>
  );
}
