import { Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import {
  CategoryQueryKeys,
  createCategory,
  updateCategory,
} from '../../lib/api/categories/categories.service';
import { ViewCategoryDto } from '../../lib/api/categories/dto/view-category.dto';
import { CreateOrUpdateCategoryDto } from '../../lib/api/categories/dto/create-or-update-category.dto';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfTextField from '../ui/forms/RhfTextField';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

interface Props {
  category?: ViewCategoryDto;
  onSuccess?: onSuccessCallback;
}

export default function AddOrUpdateCategoryForm({
  category,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const action = category ? 'Update Category' : 'Create Category';

  const defaultValues: CreateOrUpdateCategoryDto = category
    ? plainToInstance(CreateOrUpdateCategoryDto, instanceToPlain(category), {
        excludeExtraneousValues: true,
      })
    : {
        name: null,
        description: null,
      };

  //Mutations
  const createMutation = useMutation(
    (createCategoryDto: CreateOrUpdateCategoryDto) =>
      createCategory(createCategoryDto),
  );

  const updateMutation = useMutation(
    (updateCategoryDto: CreateOrUpdateCategoryDto) =>
      updateCategory(category.id, updateCategoryDto),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
    setFocus,
    control,
  } = useForm<CreateOrUpdateCategoryDto>({
    resolver: classValidatorResolver(CreateOrUpdateCategoryDto),
    defaultValues,
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
  });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateOrUpdateCategoryDto>();

  const onSubmit: SubmitHandler<CreateOrUpdateCategoryDto> = async (
    formData: CreateOrUpdateCategoryDto,
  ) => {
    clearFormSubmitError();

    try {
      let result: ViewCategoryDto;

      if (category) {
        result = await updateMutation.mutateAsync(formData);
        queryClient.invalidateQueries([
          CategoryQueryKeys.findCategoryById,
          { id: category.id },
        ]);
      } else {
        result = await createMutation.mutateAsync(formData);
      }

      queryClient.invalidateQueries([CategoryQueryKeys.findAllCategories]);

      handleShowMessage(
        `${category ? 'Updated' : 'Created'} Category '${result.name}'`,
      );
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField control={control} name="name" label="Name" required />
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            name="description"
            label="Description"
            charCountLimit={255}
            control={control}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
