import { getHeaders } from '../../util/http-utils';
import { ViewShippingAddressExtendedDto } from './dto/view-shipping-address-extended.dto';
import { apiClientFactory } from '../../common/clients';
import { UpdateBillingAddressDto } from './dto/update-billing-address.dto';
import { UpdateShippingAddressDto } from './dto/update-shipping-address.dto';
import { CreateOrUpdateContactDto } from '../contacts/dto/create-or-update-contact.dto';
import { ViewContactDto } from '../contacts/dto/view-contact.dto';
import { plainToInstance } from 'class-transformer';
import { ViewBillingAddressDto } from './dto/view-billing-address.dto';
import { ViewShippingAddressDto } from './dto/view-shipping-address.dto';
import { AddressType } from './enums/address-type.enum';
import { ViewBillingAddressExtendedDto } from './dto/view-billing-address-extended.dto';

const apiClient = apiClientFactory();

const resource = 'addresses';

/****************************
Addresses
****************************/

export async function deleteAddress(
  addressId: string,
): Promise<ViewBillingAddressDto | ViewShippingAddressDto> {
  const { data } = await apiClient.delete(`v1/${resource}/${addressId}`, {
    headers: getHeaders(),
  });

  if (data.type === AddressType.billing)
    return plainToInstance(ViewBillingAddressDto, data, {
      excludeExtraneousValues: true,
    });

  return plainToInstance(ViewShippingAddressDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateBillingAddress(
  addressId: string,
  updateAddressDto: UpdateBillingAddressDto,
): Promise<ViewBillingAddressExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${addressId}`,
    updateAddressDto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewBillingAddressExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateShippingAddress(
  addressId: string,
  updateAddressDto: UpdateShippingAddressDto,
): Promise<ViewShippingAddressExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${addressId}`,
    updateAddressDto,
    {
      headers: getHeaders(),
    },
  );

  return plainToInstance(ViewShippingAddressExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

/****************************
Address Contacts
****************************/

export async function createAddressContact(
  addressId: string,
  createContactDto: CreateOrUpdateContactDto,
): Promise<ViewContactDto> {
  const { data } = await apiClient.post(
    `v1/${resource}/${addressId}/contacts`,
    createContactDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewContactDto, data, {
    excludeExtraneousValues: true,
  });
}
