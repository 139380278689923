import { ViewOrderExtendedDto } from './../api/orders/dto/view-order-extended.dto';
import { jsPDF } from 'jspdf';
import logo from '../../assets/logo.png';
import { contrailOne } from './ContrailOne';
import { quicksandRegularNormal } from './QuicksandRegularNormal';
import { quicksandBoldNormal } from './QuicksandBoldNormal';
import { OrderType } from '../api/orders/dto/create-order.dto';
import autoTable, { CellInput, RowInput } from 'jspdf-autotable';
import Decimal from 'decimal.js';
import { getUnique, usd } from '../util/misc-utils';

enum DOCUMENT_TYPE {
  'OrderConfirmation' = 'Order Confirmation',
  'LoadingList' = 'Loading List',
  'Invoice' = 'Invoice',
}

const pageWidth = 8.5;
const pageHeight = 11;
const pageMargin = 0.5;
const headingFont = 'ContrailOne';
const contentFont = 'QuicksandRegularNormal';
const contentFontBold = 'QuicksandBoldNormal';

function setupFonts(doc: jsPDF) {
  doc.addFileToVFS('ContrailOne.ttf', contrailOne);
  doc.addFont('ContrailOne.ttf', 'ContrailOne', 'normal');
  doc.addFileToVFS('QuicksandRegularNormal.ttf', quicksandRegularNormal);
  doc.addFont('QuicksandRegularNormal.ttf', 'QuicksandRegularNormal', 'normal');
  doc.addFileToVFS('QuicksandBoldNormal.ttf', quicksandBoldNormal);
  doc.addFont('QuicksandBoldNormal.ttf', 'QuicksandBoldNormal', 'normal');
}

export const createLoadingListPdf = (order: ViewOrderExtendedDto) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [pageWidth, pageHeight],
  });

  const headerStartY = 1.75;
  const lineYIncrement = 0.2;
  const orderInfoX = 0.5;
  const billToX = 4;
  const shipToX = 6.15;

  setupFonts(doc);

  //Heading Section
  writePopesLogoAndAddress(doc, shipToX);

  const orderInfoLastLineNo = writeOrderInfo(
    DOCUMENT_TYPE.LoadingList,
    doc,
    order,
    headerStartY,
    lineYIncrement,
    orderInfoX,
    billToX,
  );

  const billToLastLineNo = writeBillTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const shipToLastLineNo = writeShipTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const headingMaxLineNo = Math.max(
    orderInfoLastLineNo,
    billToLastLineNo,
    shipToLastLineNo,
  );

  writeLoadingListTable(
    doc,
    headerStartY,
    lineYIncrement,
    headingMaxLineNo,
    order,
  );

  //   doc.autoPrint({ variant: 'non-conform' });
  doc.save(`${DOCUMENT_TYPE.LoadingList} ${order.id.substring(0, 8)}.pdf`);
};

export const createInvoicePdf = (order: ViewOrderExtendedDto) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [pageWidth, pageHeight],
  });

  const headerStartY = 1.75;
  const lineYIncrement = 0.2;
  const orderInfoX = 0.5;
  const billToX = 4;
  const shipToX = 6.15;

  setupFonts(doc);

  //Heading Section
  writePopesLogoAndAddress(doc, shipToX);

  const orderInfoLastLineNo = writeOrderInfo(
    DOCUMENT_TYPE.Invoice,
    doc,
    order,
    headerStartY,
    lineYIncrement,
    orderInfoX,
    billToX,
  );

  const billToLastLineNo = writeBillTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const shipToLastLineNo = writeShipTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const headingMaxLineNo = Math.max(
    orderInfoLastLineNo,
    billToLastLineNo,
    shipToLastLineNo,
  );

  writeInvoiceTable(doc, headerStartY, lineYIncrement, headingMaxLineNo, order);

  doc.save(`${DOCUMENT_TYPE.Invoice} ${order.id.substring(0, 8)}.pdf`);
};

export const createInvoiceAndLoadingListPdf = (order: ViewOrderExtendedDto) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [pageWidth, pageHeight],
  });

  const headerStartY = 1.75;
  const lineYIncrement = 0.2;
  const orderInfoX = 0.5;
  const billToX = 4;
  const shipToX = 6.15;

  setupFonts(doc);

  //Heading Section
  writePopesLogoAndAddress(doc, shipToX);

  const orderInfoLastLineNo = writeOrderInfo(
    DOCUMENT_TYPE.LoadingList,
    doc,
    order,
    headerStartY,
    lineYIncrement,
    orderInfoX,
    billToX,
  );

  const billToLastLineNo = writeBillTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const shipToLastLineNo = writeShipTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const headingMaxLineNo = Math.max(
    orderInfoLastLineNo,
    billToLastLineNo,
    shipToLastLineNo,
  );

  writeLoadingListTable(
    doc,
    headerStartY,
    lineYIncrement,
    headingMaxLineNo,
    order,
  );

  doc.addPage();

  //Heading Section
  writePopesLogoAndAddress(doc, shipToX);

  writeOrderInfo(
    DOCUMENT_TYPE.Invoice,
    doc,
    order,
    headerStartY,
    lineYIncrement,
    orderInfoX,
    billToX,
  );

  writeBillTo(doc, order, headerStartY, lineYIncrement, billToX, shipToX);
  writeShipTo(doc, order, headerStartY, lineYIncrement, billToX, shipToX);
  writeInvoiceTable(doc, headerStartY, lineYIncrement, headingMaxLineNo, order);

  //   doc.autoPrint({ variant: 'non-conform' });
  doc.save(
    `${DOCUMENT_TYPE.LoadingList} and ${
      DOCUMENT_TYPE.Invoice
    } ${order.id.substring(0, 8)}.pdf`,
  );
};

export const createOrderConfirmationPdf = (order: ViewOrderExtendedDto) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [pageWidth, pageHeight],
  });

  const headerStartY = 1.75;
  const lineYIncrement = 0.2;
  const orderInfoX = 0.5;
  const billToX = 4;
  const shipToX = 6.15;

  setupFonts(doc);

  //Heading Section
  writePopesLogoAndAddress(doc, shipToX);

  const orderInfoLastLineNo = writeOrderInfo(
    DOCUMENT_TYPE.OrderConfirmation,
    doc,
    order,
    headerStartY,
    lineYIncrement,
    orderInfoX,
    billToX,
  );

  const billToLastLineNo = writeBillTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const shipToLastLineNo = writeShipTo(
    doc,
    order,
    headerStartY,
    lineYIncrement,
    billToX,
    shipToX,
  );

  const headingMaxLineNo = Math.max(
    orderInfoLastLineNo,
    billToLastLineNo,
    shipToLastLineNo,
  );

  writeOrderConfirmationTable(
    doc,
    headerStartY,
    lineYIncrement,
    headingMaxLineNo,
    order,
  );

  doc.save(
    `${DOCUMENT_TYPE.OrderConfirmation} ${order.id.substring(0, 8)}.pdf`,
  );
};

function writePopesLogoAndAddress(doc: jsPDF, xCor: number) {
  doc.addImage(logo, 'PNG', pageMargin, 0.3, 1.25, 0.68125);
  doc.setFont(contentFontBold);
  doc.setFontSize(9);
  doc.text('P.O. Box 187', xCor, 0.5);
  doc.text('Greenback, TN 37742', xCor, 0.7);
  doc.text('(865) 977-6942', xCor, 0.9);
  doc.text('orders@popesplantfarm.com', xCor, 1.1);
}

function writeOrderInfo(
  type: DOCUMENT_TYPE,
  doc: jsPDF,
  order: ViewOrderExtendedDto,
  yCorStart: number,
  yInc: number,
  orderInfoX: number,
  billToColX: number,
) {
  let line = 0;

  //Document Title
  doc.setFontSize(16);
  doc.setFont(headingFont);
  doc.text(`${type} ${order.id.substring(0, 8)}`, orderInfoX, 1.5, {
    maxWidth: 7.9,
  });

  //Order Info Headings
  doc.setFont(contentFontBold);
  doc.setFontSize(10);
  doc.text('Date Placed: ', orderInfoX, yCorStart + yInc * line);
  line++;
  doc.text('Placed By: ', orderInfoX, yCorStart + yInc * line);
  line++;
  doc.text('Order Week: ', orderInfoX, yCorStart + yInc * line);
  line++;
  doc.text('Route: ', orderInfoX, yCorStart + yInc * line);
  line++;
  if (order.poNumber) {
    doc.text('PO #: ', orderInfoX, yCorStart + yInc * line);
    line++;
  }

  if (order.note || order.customer.notes) {
    doc.text('Note: ', orderInfoX, yCorStart + yInc * line);
    line++;
  }

  // Order Info Content
  doc.setFont(contentFont);
  doc.setFontSize(10);
  line = 0;

  doc.text(order.createdAt.toFormat('M/d/yyyy'), 1.38, yCorStart + yInc * line);
  line++;

  doc.text(order.placedBy, 1.25, yCorStart + yInc * line);
  line++;

  doc.text(order.orderWeek, 1.38, yCorStart + yInc * line);
  line++;

  doc.text(order.deliveryRouteName || '-', 1, yCorStart + yInc * line);
  line++;

  if (order.poNumber) {
    doc.text(order.poNumber, 0.9, yCorStart + yInc * line);
    line++;
  }

  if (order.note || order.customer.notes) {
    const notes = `${order.note || ''} ${order.customer.notes || ''}`.trim();
    const noteParts: string[] = doc.splitTextToSize(
      notes,
      billToColX - 0.9 - 0.05,
    );

    noteParts.forEach((lineText) => {
      doc.text(lineText, 0.9, yCorStart + yInc * line);
      line++;
    });
  }

  return line;
}

function writeBillTo(
  doc: jsPDF,
  order: ViewOrderExtendedDto,
  yCorStart: number,
  yInc: number,
  billToColX: number,
  shipToColX: number,
) {
  let line = 0;

  doc.setFont(headingFont);
  doc.text('Bill To', billToColX, yCorStart + yInc * line);
  line++;

  const billToColWidth = shipToColX - billToColX - 0.1;

  doc.setFont(contentFont);
  const nameParts: string[] = doc.splitTextToSize(
    order.billingCompanyName,
    billToColWidth,
  );
  nameParts.forEach((val) => {
    doc.text(val, billToColX, yCorStart + yInc * line);
    line++;
  });

  if (order.billingStreet1) {
    const street1Parts: string[] = doc.splitTextToSize(
      order.billingStreet1,
      billToColWidth,
    );

    street1Parts.forEach((val) => {
      doc.text(val, billToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.billingStreet2) {
    const street2Parts: string[] = doc.splitTextToSize(
      order.billingStreet2,
      billToColWidth,
    );

    street2Parts.forEach((val) => {
      doc.text(val, billToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.billingPOBox) {
    doc.text(
      'PO Box ' + order.billingPOBox,
      billToColX,
      yCorStart + yInc * line,
    );
    line++;
  }

  if (order.billingCity) {
    const cityParts: string[] = doc.splitTextToSize(
      order.billingCity + ', ' + order.billingState + ' ' + order.billingZip,
      billToColWidth,
    );

    cityParts.forEach((val) => {
      doc.text(val, billToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (
    order.billingFirstName ||
    order.billingLastName ||
    order.billingEmail ||
    order.billingMobilePhone ||
    order.billingOfficePhone ||
    order.billingFax
  ) {
    doc.setDrawColor(212, 212, 212);
    doc.setLineWidth(0.01);
    doc.line(
      billToColX,
      yCorStart + yInc * (line - 0.7),
      billToColX + billToColWidth,
      yCorStart + yInc * (line - 0.7),
    );
  }

  if (order.billingFirstName || order.billingLastName) {
    const fullName = `${order.billingFirstName || ''} ${
      order.billingLastName || ''
    }`.trim();
    const firstNameParts: string[] = doc.splitTextToSize(
      fullName,
      billToColWidth,
    );

    firstNameParts.forEach((val) => {
      doc.text(val, billToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.billingEmail) {
    const billingEmailParts: string[] = doc.splitTextToSize(
      order.billingEmail,
      billToColWidth,
    );

    billingEmailParts.forEach((val) => {
      doc.text(val, billToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.billingMobilePhone) {
    doc.text(order.billingMobilePhone, billToColX, yCorStart + yInc * line);
    line++;
  }
  if (order.billingOfficePhone) {
    doc.text(order.billingOfficePhone, billToColX, yCorStart + yInc * line);
    line++;
  }
  if (order.billingFax) {
    doc.text(order.billingFax, billToColX, yCorStart + yInc * line);
    line++;
  }

  return line;
}

function writeShipTo(
  doc: jsPDF,
  order: ViewOrderExtendedDto,
  yCorStart: number,
  yInc: number,
  billToColX: number,
  shipToColX: number,
) {
  let line = 0;

  doc.setFont(headingFont);
  doc.text('Ship To', shipToColX, yCorStart + yInc * line);
  line++;

  doc.setFont(contentFont);
  if (order.type === OrderType.PickupGreenback) {
    doc.text('Pickup @ Greenback', shipToColX, yCorStart + yInc * line);
    line++;
    doc.text('6814 Hwy 411 S.', shipToColX, yCorStart + yInc * line);
    line++;
    doc.text('Greenback, TN 37742', shipToColX, yCorStart + yInc * line);
    line++;
    return line;
  }

  if (order.type === OrderType.PickupMaryville) {
    doc.text('Pickup @ Maryville', shipToColX, yCorStart + yInc * line);
    line++;
    doc.text('736 Alcoa Trail', shipToColX, yCorStart + yInc * line);
    line++;
    doc.text('Maryville, TN 37804', shipToColX, yCorStart + yInc * line);
    line++;
    return line;
  }

  const shipToColWidth = pageWidth - pageMargin - shipToColX;

  const nameParts: string[] = doc.splitTextToSize(
    order.shippingCompanyName,
    shipToColWidth,
  );
  nameParts.forEach((val) => {
    doc.text(val, shipToColX, yCorStart + yInc * line);
    line++;
  });

  if (order.shippingStreet1) {
    const street1Parts: string[] = doc.splitTextToSize(
      order.shippingStreet1,
      shipToColWidth,
    );

    street1Parts.forEach((val) => {
      doc.text(val, shipToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.shippingStreet2) {
    const street2Parts: string[] = doc.splitTextToSize(
      order.shippingStreet2,
      shipToColWidth,
    );

    street2Parts.forEach((val) => {
      doc.text(val, shipToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.shippingCity) {
    const cityParts: string[] = doc.splitTextToSize(
      order.shippingCity + ', ' + order.shippingState + ' ' + order.shippingZip,
      shipToColWidth,
    );

    cityParts.forEach((val) => {
      doc.text(val, shipToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (
    order.shippingFirstName ||
    order.shippingLastName ||
    order.shippingEmail ||
    order.shippingMobilePhone ||
    order.shippingOfficePhone ||
    order.shippingFax
  ) {
    doc.setDrawColor(212, 212, 212);
    doc.setLineWidth(0.01);
    doc.line(
      shipToColX,
      yCorStart + yInc * (line - 0.7),
      shipToColX + shipToColWidth,
      yCorStart + yInc * (line - 0.7),
    );
  }

  if (order.shippingFirstName || order.shippingLastName) {
    const fullName = `${order.shippingFirstName || ''} ${
      order.shippingLastName || ''
    }`.trim();
    const firstNameParts: string[] = doc.splitTextToSize(
      fullName,
      shipToColWidth,
    );

    firstNameParts.forEach((val) => {
      doc.text(val, shipToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.shippingEmail) {
    const shippingEmailParts: string[] = doc.splitTextToSize(
      order.shippingEmail,
      shipToColWidth,
    );

    shippingEmailParts.forEach((val) => {
      doc.text(val, shipToColX, yCorStart + yInc * line);
      line++;
    });
  }

  if (order.shippingMobilePhone) {
    doc.text(order.shippingMobilePhone, shipToColX, yCorStart + yInc * line);
    line++;
  }
  if (order.shippingOfficePhone) {
    doc.text(order.shippingOfficePhone, shipToColX, yCorStart + yInc * line);
    line++;
  }
  if (order.shippingFax) {
    doc.text(order.shippingFax, shipToColX, yCorStart + yInc * line);
    line++;
  }

  return line;
}

function writeLoadingListTable(
  doc: jsPDF,
  yCorStart: number,
  yInc: number,
  line: number,
  order: ViewOrderExtendedDto,
) {
  //have 7.5" to work with b/c of 0.5 margins
  const cellWidths = [6, 0.75, 0.75];

  const head: RowInput[] = [
    [
      {
        content: 'Category/Product',
        styles: {
          cellWidth: cellWidths[0],
          halign: 'left',
        },
      },
      {
        content: 'Qty',
        styles: {
          cellWidth: cellWidths[1],
          halign: 'right',
        },
      },
      {
        content: 'Racks',
        styles: {
          cellWidth: cellWidths[2],
          halign: 'right',
        },
      },
    ],
  ];

  //1. Get a List of Unique Category Names
  const uniqueCategoryNames = getUnique<string>(order.items, 'categoryName');

  //2. For each category, write a category row, followed by all the items on the
  //   order that are related to that row.
  const data: RowInput[] = [];

  uniqueCategoryNames.forEach((categoryName) => {
    //Get the order items that belong to this catgory.
    const itemsForCategory = order.items
      .filter((i) => i.categoryName === categoryName)
      .sort((a, b) => (a.variantName > b.variantName ? 1 : -1))
      .sort((a, b) =>
        a.productName === b.productName
          ? 0
          : a.productName > b.productName
          ? 1
          : -1,
      );
    //Build a table row for each of those items.
    const itemRows: CellInput[][] = itemsForCategory.map(
      (item): CellInput[] => {
        const itemName = `${item.productName || ''} ${
          item.variantName || ''
        }`.trim();

        const itemDescription = `${item.productDescription || ''} ${
          item.variantDescription || ''
        }`.trim();

        let nameColContent = itemName;

        if (itemDescription.length > 0)
          nameColContent += ` (${itemDescription})`;

        return [
          {
            content: nameColContent,
            styles: {
              halign: 'left',
              cellWidth: cellWidths[0],
            },
          },
          {
            content: item.qty.toFixed(2),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[1],
            },
          },
          {
            content: item.racks.toFixed(3),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[2],
            },
          },
          ,
        ];
      },
    );

    //Combine the category row with the item rows and push it onto our table data
    data.push(
      // The Cateogry Row
      [
        {
          content: categoryName,
          colSpan: 3,
          styles: {
            halign: 'left',
            fillColor: '#E9ECEF',
          },
        },
      ],
      // The Item Rows
      ...itemRows,
    );
  });

  //Table for the Categories and Line Items
  autoTable(doc, {
    startY: yCorStart + line * yInc,
    margin: pageMargin,
    head: head,
    body: data,
    headStyles: {
      font: headingFont,
      fillColor: '#2d723b',
      textColor: '#fff',
      valign: 'middle',
    },
    bodyStyles: {
      font: contentFont,
      valign: 'middle',
      lineColor: '#eee',
      lineWidth: 0.005,
    },
    theme: 'plain',
    horizontalPageBreak: true,
  });

  const finalYOfTable = (doc as any).lastAutoTable.finalY;

  doc.line(pageMargin, finalYOfTable, pageWidth - pageMargin, finalYOfTable);

  //Table for the Summary Totals
  autoTable(doc, {
    startY: finalYOfTable + 0.05,
    margin: pageMargin,
    body: [
      [
        {
          content: 'Racks',
          styles: {
            cellWidth: cellWidths[0] + cellWidths[1],
          },
        },
        {
          content: order.items
            .map((i) => i.racks)
            .reduce((acc, cur) => acc.plus(cur), new Decimal(0))
            .toFixed(3),
          styles: {
            cellWidth: cellWidths[2],
          },
        },
      ],
    ],
    bodyStyles: {
      font: contentFontBold,
      fillColor: '#fff',
      valign: 'middle',
      halign: 'right',
    },
    theme: 'plain',
    showHead: false,
  });
}

function writeInvoiceTable(
  doc: jsPDF,
  yCorStart: number,
  yInc: number,
  line: number,
  order: ViewOrderExtendedDto,
) {
  //have 7.5" to work with b/c of 0.5 margins
  const hasDiscount = order.customerDiscountTotal.gt(0);

  let cellWidths: number[] = [];

  if (hasDiscount) {
    cellWidths = [2.6, 0.6, 0.6, 0.7, 1, 1, 1];
  } else {
    cellWidths = [4.5, 0.65, 0.65, 0.7, 1];
  }

  const head: CellInput[][] = [
    [
      {
        content: 'Category/Product',
        styles: {
          cellWidth: cellWidths[0],
          halign: 'left',
        },
      },
      {
        content: 'Item #',
        styles: {
          cellWidth: cellWidths[1],
          halign: 'center',
        },
      },
      {
        content: 'Qty',
        styles: {
          cellWidth: cellWidths[2],
          halign: 'right',
        },
      },
      {
        content: 'Unit Price',
        styles: {
          cellWidth: cellWidths[3],
          halign: 'right',
        },
      },
      {
        content: 'Extended Price',
        styles: {
          cellWidth: cellWidths[4],
          halign: 'right',
        },
      },
    ],
  ];

  if (hasDiscount) {
    head[0].push({
      content: `Discount (${order.customerDiscountRate.toNumber()}%)`,
      styles: {
        cellWidth: cellWidths[5],
        halign: 'right',
      },
    });
    head[0].push({
      content: 'Price After Discount',
      styles: {
        cellWidth: cellWidths[6],
        halign: 'right',
      },
    });
  }

  //1. Get a List of Unique Category Names So we can group display by category.
  const uniqueCategoryNames = getUnique<string>(order.items, 'categoryName');

  //2. For each category, write a category row, followed by all the items on the
  //   order that are related to that row.
  const data: RowInput[] = [];

  uniqueCategoryNames.forEach((uniqueCategoryName) => {
    //Get the order items that belong to this catgory.
    const itemsForCategory = order.items.filter(
      (i) => i.categoryName === uniqueCategoryName,
    );

    //Group by Product
    //--Get unique products in the category.
    const uniqueProductNames = getUnique<string>(
      itemsForCategory,
      'productName',
    );

    //Build a table row for each unique product item.
    const productItemRows: CellInput[][] = uniqueProductNames.map(
      (uniqueProductName): CellInput[] => {
        const allItemsForProduct = itemsForCategory.filter(
          (lineItem) => lineItem.productName === uniqueProductName,
        );
        const firstLineItemForProduct = allItemsForProduct[0];

        let nameColContent = firstLineItemForProduct.productName;
        const itemDescription = `${
          firstLineItemForProduct.productDescription || ''
        }`.trim();

        if (itemDescription.length > 0)
          nameColContent += ` (${itemDescription})`;

        const row: CellInput[] = [
          {
            content: nameColContent,
            styles: {
              halign: 'left',
              cellWidth: cellWidths[0],
            },
          },
          {
            content: firstLineItemForProduct.productItemNo || '-',
            styles: {
              halign: 'center',
              cellWidth: cellWidths[1],
            },
          },
          {
            content: allItemsForProduct
              .reduce((acc, cur) => acc.plus(cur.qty), new Decimal(0))
              .toFixed(2),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[2],
            },
          },
          {
            content: usd(firstLineItemForProduct.price),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[3],
            },
          },
          {
            content: usd(
              allItemsForProduct.reduce(
                (acc, cur) => acc.plus(cur.productTotalBeforeDiscount),
                new Decimal(0),
              ),
            ),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[4],
            },
          },
        ];

        // Add the discount columns if needed
        if (hasDiscount) {
          row.push({
            content: usd(
              allItemsForProduct
                .reduce(
                  (acc, cur) =>
                    acc.plus(
                      order.customerDiscountRate
                        .dividedBy(100)
                        .times(cur.productTotalBeforeDiscount)
                        .toNearest(0.01),
                    ),
                  new Decimal(0),
                )
                .neg()
                .toNearest(0.01),
            ),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[5],
            },
          });
          row.push({
            content: usd(
              allItemsForProduct
                .reduce(
                  (acc, cur) =>
                    acc.plus(
                      cur.productTotalBeforeDiscount.minus(
                        order.customerDiscountRate
                          .dividedBy(100)
                          .times(cur.productTotalBeforeDiscount)
                          .toNearest(0.01),
                      ),
                    ),
                  new Decimal(0),
                )
                .toNearest(0.01),
            ),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[6],
            },
          });
        }

        return row;
      },
    );

    //Combine the category row with the item rows and push it onto our data
    data.push(
      // The Cateogry Row
      [
        {
          content: uniqueCategoryName,
          colSpan: hasDiscount ? 7 : 5,
          styles: {
            halign: 'left',
            fillColor: '#E9ECEF',
          },
        },
      ],
      // The Item Rows
      ...productItemRows,
    );
  });

  //Table for the Categories and Line Items
  autoTable(doc, {
    startY: yCorStart + line * yInc,
    margin: pageMargin,
    head: head,
    body: data,
    headStyles: {
      font: headingFont,
      fillColor: '#2d723b',
      textColor: '#fff',
      valign: 'middle',
    },
    bodyStyles: {
      font: contentFont,
      valign: 'middle',
      lineColor: '#eee',
      lineWidth: 0.005,
    },
    theme: 'plain',
    horizontalPageBreak: true,
  });

  const finalYOfItemsTable = (doc as any).lastAutoTable.finalY;
  const footerFirstColWidth =
    cellWidths[0] + cellWidths[1] + cellWidths[2] + cellWidths[3];

  doc.line(
    pageMargin,
    finalYOfItemsTable,
    pageWidth - pageMargin,
    finalYOfItemsTable,
  );

  //Table for the Summary Totals
  const subtotalRow: CellInput[] = [
    {
      content: 'Sub Totals',
      styles: {
        cellWidth: footerFirstColWidth,
      },
    },
    {
      content: usd(order.productTotalBeforeDiscounts),
      styles: {
        cellWidth: cellWidths[4],
      },
    },
  ];

  if (hasDiscount) {
    subtotalRow.push(
      {
        content: usd(order.customerDiscountTotal.neg()),
        styles: {
          cellWidth: cellWidths[5],
        },
      },
      {
        content: usd(
          order.productTotalBeforeDiscounts.minus(order.customerDiscountTotal),
        ),
        styles: {
          cellWidth: cellWidths[6],
        },
      },
    );
  }

  const fuelSurchargeRow: CellInput[] = [
    {
      content: 'Fuel Surcharge',
      colSpan: hasDiscount ? 3 : 1,
    },
    {
      content: usd(order.fuelSurchargeTotal),
      styles: {
        cellWidth: hasDiscount ? cellWidths[6] : cellWidths[4],
      },
    },
  ];

  autoTable(doc, {
    startY: finalYOfItemsTable + 0.05,
    margin: pageMargin,
    body: order.fuelSurchargeTotal.greaterThan(0)
      ? [subtotalRow, fuelSurchargeRow]
      : [subtotalRow],
    bodyStyles: {
      font: contentFontBold,
      fillColor: '#fff',
      valign: 'middle',
      halign: 'right',
    },
    theme: 'plain',
    showHead: false,
  });

  const orderTotalStartColWidth = hasDiscount
    ? cellWidths[0] +
      cellWidths[1] +
      cellWidths[2] +
      cellWidths[3] +
      cellWidths[4] +
      cellWidths[5]
    : cellWidths[0] + cellWidths[1] + cellWidths[2] + cellWidths[3];

  const finalYOfSubtotalTable = (doc as any).lastAutoTable.finalY;
  doc.line(
    pageWidth - pageMargin - 2.1,
    finalYOfSubtotalTable,
    pageWidth - pageMargin,
    finalYOfSubtotalTable,
  );

  const orderTotalRow: CellInput[] = [
    {
      content: 'Order Total',
      styles: {
        cellWidth: orderTotalStartColWidth,
      },
    },
    {
      content: usd(order.orderTotal),
      styles: {
        cellWidth: hasDiscount ? cellWidths[6] : cellWidths[4],
      },
    },
  ];

  autoTable(doc, {
    startY: finalYOfSubtotalTable + 0.05,
    margin: pageMargin,
    body: [orderTotalRow],
    bodyStyles: {
      font: contentFontBold,
      fillColor: '#fff',
      valign: 'middle',
      halign: 'right',
    },
    theme: 'plain',
    showHead: false,
  });
}

function writeOrderConfirmationTable(
  doc: jsPDF,
  yCorStart: number,
  yInc: number,
  line: number,
  order: ViewOrderExtendedDto,
) {
  //have 7.5" to work with b/c of 0.5 margins
  const hasDiscount = order.customerDiscountTotal.gt(0);

  let cellWidths: number[] = [];

  if (hasDiscount) {
    cellWidths = [2.6, 0.6, 0.6, 0.7, 1, 1, 1];
  } else {
    cellWidths = [4.5, 0.65, 0.65, 0.7, 1];
  }

  const head: CellInput[][] = [
    [
      {
        content: 'Category/Product',
        styles: {
          cellWidth: cellWidths[0],
          halign: 'left',
        },
      },
      {
        content: 'Item #',
        styles: {
          cellWidth: cellWidths[1],
          halign: 'center',
        },
      },
      {
        content: 'Qty',
        styles: {
          cellWidth: cellWidths[2],
          halign: 'right',
        },
      },
      {
        content: 'Unit Price',
        styles: {
          cellWidth: cellWidths[3],
          halign: 'right',
        },
      },
      {
        content: 'Extended Price',
        styles: {
          cellWidth: cellWidths[4],
          halign: 'right',
        },
      },
    ],
  ];

  if (hasDiscount) {
    head[0].push({
      content: `Discount (${order.customerDiscountRate.toNumber()}%)`,
      styles: {
        cellWidth: cellWidths[5],
        halign: 'right',
      },
    });
    head[0].push({
      content: 'Price After Discount',
      styles: {
        cellWidth: cellWidths[6],
        halign: 'right',
      },
    });
  }

  //1. Get a List of Unique Category Names So we can group display by category.
  const uniqueCategoryNames = getUnique<string>(order.items, 'categoryName');

  //2. For each category, write a category row, followed by all the items on the
  //   order that are related to that row.
  const data: RowInput[] = [];

  uniqueCategoryNames.forEach((uniqueCategoryName) => {
    //Get the order items that belong to this catgory.
    const itemsForCategory = order.items.filter(
      (i) => i.categoryName === uniqueCategoryName,
    );

    //Group by Product
    //--Get unique products in the category.
    const uniqueProductNames = getUnique<string>(
      itemsForCategory,
      'productName',
    );

    //Build a table row for each unique product item.
    const productItemRows: CellInput[][] = uniqueProductNames.map(
      (uniqueProductName): CellInput[] => {
        const allItemsForProduct = itemsForCategory.filter(
          (lineItem) => lineItem.productName === uniqueProductName,
        );
        const firstLineItemForProduct = allItemsForProduct[0];

        let nameColContent = firstLineItemForProduct.productName;
        const itemDescription = `${
          firstLineItemForProduct.productDescription || ''
        }`.trim();

        if (itemDescription.length > 0)
          nameColContent += ` (${itemDescription})`;

        const row: CellInput[] = [
          {
            content: nameColContent,
            styles: {
              halign: 'left',
              cellWidth: cellWidths[0],
            },
          },
          {
            content: firstLineItemForProduct.productItemNo || '-',
            styles: {
              halign: 'center',
              cellWidth: cellWidths[1],
            },
          },
          {
            content: allItemsForProduct
              .reduce((acc, cur) => acc.plus(cur.qty), new Decimal(0))
              .toFixed(2),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[2],
            },
          },
          {
            content: usd(firstLineItemForProduct.price),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[3],
            },
          },
          {
            content: usd(
              allItemsForProduct.reduce(
                (acc, cur) => acc.plus(cur.productTotalBeforeDiscount),
                new Decimal(0),
              ),
            ),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[4],
            },
          },
        ];

        // Add the discount columns if needed
        if (hasDiscount) {
          row.push({
            content: usd(
              allItemsForProduct
                .reduce(
                  (acc, cur) =>
                    acc.plus(
                      order.customerDiscountRate
                        .dividedBy(100)
                        .times(cur.productTotalBeforeDiscount)
                        .toNearest(0.01),
                    ),
                  new Decimal(0),
                )
                .neg()
                .toNearest(0.01),
            ),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[5],
            },
          });
          row.push({
            content: usd(
              allItemsForProduct
                .reduce(
                  (acc, cur) =>
                    acc.plus(
                      cur.productTotalBeforeDiscount.minus(
                        order.customerDiscountRate
                          .dividedBy(100)
                          .times(cur.productTotalBeforeDiscount)
                          .toNearest(0.01),
                      ),
                    ),
                  new Decimal(0),
                )
                .toNearest(0.01),
            ),
            styles: {
              halign: 'right',
              cellWidth: cellWidths[6],
            },
          });
        }

        return row;
      },
    );

    //Combine the category row with the item rows and push it onto our data
    data.push(
      // The Cateogry Row
      [
        {
          content: uniqueCategoryName,
          colSpan: hasDiscount ? 7 : 5,
          styles: {
            halign: 'left',
            fillColor: '#E9ECEF',
          },
        },
      ],
      // The Item Rows
      ...productItemRows,
    );
  });

  //Table for the Categories and Line Items
  autoTable(doc, {
    startY: yCorStart + line * yInc,
    margin: pageMargin,
    head: head,
    body: data,
    headStyles: {
      font: headingFont,
      fillColor: '#2d723b',
      textColor: '#fff',
      valign: 'middle',
    },
    bodyStyles: {
      font: contentFont,
      valign: 'middle',
      lineColor: '#eee',
      lineWidth: 0.005,
    },
    theme: 'plain',
    horizontalPageBreak: true,
  });

  const finalYOfItemsTable = (doc as any).lastAutoTable.finalY;
  const footerFirstColWidth =
    cellWidths[0] + cellWidths[1] + cellWidths[2] + cellWidths[3];

  doc.line(
    pageMargin,
    finalYOfItemsTable,
    pageWidth - pageMargin,
    finalYOfItemsTable,
  );

  //Table for the Summary Totals
  const subtotalRow: CellInput[] = [
    {
      content: 'Sub Totals',
      styles: {
        cellWidth: footerFirstColWidth,
      },
    },
    {
      content: usd(order.productTotalBeforeDiscounts),
      styles: {
        cellWidth: cellWidths[4],
      },
    },
  ];

  if (hasDiscount) {
    subtotalRow.push(
      {
        content: usd(order.customerDiscountTotal.neg()),
        styles: {
          cellWidth: cellWidths[5],
        },
      },
      {
        content: usd(
          order.productTotalBeforeDiscounts.minus(order.customerDiscountTotal),
        ),
        styles: {
          cellWidth: cellWidths[6],
        },
      },
    );
  }

  const fuelSurchargeRow: CellInput[] = [
    {
      content: 'Fuel Surcharge',
      colSpan: hasDiscount ? 3 : 1,
    },
    {
      content: usd(order.fuelSurchargeTotal),
      styles: {
        cellWidth: hasDiscount ? cellWidths[6] : cellWidths[4],
      },
    },
  ];

  autoTable(doc, {
    startY: finalYOfItemsTable + 0.05,
    margin: pageMargin,
    body: order.fuelSurchargeTotal.greaterThan(0)
      ? [subtotalRow, fuelSurchargeRow]
      : [subtotalRow],
    bodyStyles: {
      font: contentFontBold,
      fillColor: '#fff',
      valign: 'middle',
      halign: 'right',
    },
    theme: 'plain',
    showHead: false,
  });

  const orderTotalStartColWidth = hasDiscount
    ? cellWidths[0] +
      cellWidths[1] +
      cellWidths[2] +
      cellWidths[3] +
      cellWidths[4] +
      cellWidths[5]
    : cellWidths[0] + cellWidths[1] + cellWidths[2] + cellWidths[3];

  const finalYOfSubtotalTable = (doc as any).lastAutoTable.finalY;
  doc.line(
    pageWidth - pageMargin - 2.1,
    finalYOfSubtotalTable,
    pageWidth - pageMargin,
    finalYOfSubtotalTable,
  );

  const orderTotalRow: CellInput[] = [
    {
      content: 'Order Total',
      styles: {
        cellWidth: orderTotalStartColWidth,
      },
    },
    {
      content: usd(order.orderTotal),
      styles: {
        cellWidth: hasDiscount ? cellWidths[6] : cellWidths[4],
      },
    },
  ];

  autoTable(doc, {
    startY: finalYOfSubtotalTable + 0.05,
    margin: pageMargin,
    body: [orderTotalRow],
    bodyStyles: {
      font: contentFontBold,
      fillColor: '#fff',
      valign: 'middle',
      halign: 'right',
    },
    theme: 'plain',
    showHead: false,
  });
}

// /**
//  * Takes some text and produces an array of strings that represent the text
//  * fitting into a desired number of characters per line. If an individual
//  * word in the text is longer than the desired number charaters per line it
//  * will be hyphenated across as many lines as needed.
//  * @param text
//  * @param charsPerLine
//  */
// function getLines(text: string, charsPerLine: number) {
//   const words = text.split(' ');
//   const lines: string[] = [];

//   words.forEach((word) => {
//     const currentLineIndex = lines.length === 0 ? 0 : lines.length - 1;
//     const currentLineChars = lines[currentLineIndex]?.length || 0;

//     //Init line to empty string
//     if (currentLineChars === 0) lines[currentLineIndex] = '';

//     //Let's make sure the word can fit on a single line
//     if (word.length > charsPerLine) {
//       //Word cannot fit on single line, we need to hyphenate
//       const linesNeededForWord = Math.ceil(word.length / charsPerLine);

//       for (let i = 0; i <= linesNeededForWord - 1; i++) {
//         const fractionOfWord = word.substring(
//           i * (charsPerLine - 1),
//           (i + 1) * (charsPerLine - 1),
//         );
//         if (i < linesNeededForWord - 1) {
//           //Not the last line needed for the word, add hypen to end
//           if (lines.length === 1 && lines[0].length === 0) {
//             lines[0] = fractionOfWord + '-';
//           } else {
//             lines.push(fractionOfWord + '-');
//           }
//         } else {
//           //Last entry, don't include the hyphen
//           lines.push(fractionOfWord + ' ');
//         }
//       }
//       return;
//     }

//     if (currentLineChars + word.length <= charsPerLine) {
//       //The word can fit on current line, lets add it.
//       lines[currentLineIndex] += word + ' ';
//     } else {
//       //The word cannot fit on current line, let's add it to the next line
//       lines.push(word + ' ');
//     }
//   });

//   return lines.map((l) => l.trim());
// }
