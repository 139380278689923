import { IconButton, styled } from '@mui/material';

const IconButtonBase = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[400],
  borderRadius: '5px',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
    '& svg': {
      // fill: theme.palette.error.main,
    },
  },
}));

export default IconButtonBase;
