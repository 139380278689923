import { Expose } from 'class-transformer';
import { IsEnum, IsOptional, IsString } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';
import { IsNumericString } from '../../../common/custom-class-validators/IsNumericString';
import { NumericStringMax } from '../../../common/custom-class-validators/NumericStringMax';
import { NumericStringMin } from '../../../common/custom-class-validators/NumericStringMin';
import { CustomerType } from './view-customer.dto';

export class CreateOrUpdateCustomerDto {
  @IsString()
  @CustomLength(3, 100, 'Name')
  @CustomIsNotEmpty('Name')
  @Expose()
  name: string;

  @IsEnum(CustomerType, { message: `Customer Type is required.` })
  @CustomIsNotEmpty('Type')
  @Expose()
  type: CustomerType;

  @IsOptional()
  @IsNumericString(0, 2)
  @NumericStringMin(0)
  @NumericStringMax(100)
  @Expose()
  discountRate?: string;

  @IsOptional()
  @IsString()
  @Expose()
  notes?: string;
}
