import { Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfTextField from '../ui/forms/RhfTextField';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';
import { ViewOrderExtendedDto } from '../../lib/api/orders/dto/view-order-extended.dto';
import {
  OrderQueryKeys,
  updateOrderPO,
} from '../../lib/api/orders/orders.service';
import { UpdateOrderPODto } from '../../lib/api/orders/dto/update-order-po.dto';

interface Props {
  order: ViewOrderExtendedDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateOrderPOForm({ order, onSuccess }: Props) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const action = order.poNumber ? 'Update Order PO #' : 'Add PO #';

  //Mutations
  const updateMutation = useMutation((updateOrderPODto: UpdateOrderPODto) =>
    updateOrderPO(order.id, updateOrderPODto),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    setError,
    setFocus,
  } = useForm<UpdateOrderPODto>({
    resolver: classValidatorResolver(UpdateOrderPODto),
    mode: 'onTouched',
    defaultValues: {
      poNumber: order.poNumber || '',
    },
    delayError: VALIDATION_ERR_DELAY,
  });

  useEffect(() => {
    setFocus('poNumber');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateOrderPODto>();

  const onSubmit: SubmitHandler<UpdateOrderPODto> = async (
    formData: UpdateOrderPODto,
  ) => {
    clearFormSubmitError();

    try {
      let result: ViewOrderExtendedDto;

      //Updating
      result = await updateMutation.mutateAsync(formData);
      queryClient.invalidateQueries([
        OrderQueryKeys.findOrderById,
        { id: order.id },
      ]);

      queryClient.invalidateQueries([OrderQueryKeys.findAllOrders]);
      handleShowMessage('Order PO # Updated');
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField name="poNumber" label="PO #" control={control} />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
