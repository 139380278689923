import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import AdminAppBarHeader from '../components/AdminAppBarHeader';
import Footer from '../components/Footer';
import AdminSidebarNav, {
  NavigationItem,
  NavigationItemExpandable,
} from '../components/AdminSidebarNav';
import {
  AdminLayoutProvider,
  DetailsDrawerConfig,
} from '../context/AdminLayoutContext';
import { ObjectWithId } from '../../../lib/util/table-util';
import StoreIcon from '@mui/icons-material/Store';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import InventoryIcon from '@mui/icons-material/Inventory';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import BallotIcon from '@mui/icons-material/Ballot';
import _ from 'lodash';

const menuDrawerWidth = 300;
const detailsDrawerWidth = 600;

export default function AdminLayout<ViewDto extends ObjectWithId>() {
  const navigate = useNavigate();
  const location = useLocation();

  //Menu
  const activePath = location.pathname.split('/').pop();

  useEffect(() => {
    if (activePath) handleMenuNavigation(activePath);
  }, [activePath]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [pages, setPages] = useState<
    Array<NavigationItem | NavigationItemExpandable>
  >([
    {
      key: 'orders',
      displayText: 'Orders',
      icon: <ReceiptLongIcon />,
      active: activePath === 'orders',
    },
    {
      key: 'fulfillment',
      displayText: 'Order Fulfillment',
      icon: <BallotIcon />,
      active: activePath === 'fulfillment',
    },
    {
      key: 'store',
      displayText: 'Store',
      icon: <StorefrontIcon />,
      active: activePath === 'store',
    },
    {
      key: 'cart',
      displayText: 'Cart',
      icon: <ShoppingCartIcon />,
      active: activePath === 'cart',
    },
    {
      key: 'customers',
      displayText: 'Customers',
      icon: <StoreIcon />,
      active: activePath === 'customers',
    },
    {
      key: 'users',
      displayText: 'Users',
      icon: <GroupIcon />,
      active: activePath === 'users',
    },
    {
      key: 'delivery-routes',
      displayText: 'Delivery Routes',
      icon: <LocalShippingIcon />,
      active: activePath === 'delivery-routes',
    },
    {
      key: 'inventory',
      displayText: 'Inventory',
      icon: <InventoryIcon />,
      active: activePath === 'inventory',
    },
    {
      key: 'products-menu',
      displayText: 'Products',
      icon: <LocalFloristIcon />,
      active: activePath === 'categories' || activePath === 'products',
      open: false,
      children: [
        {
          key: 'categories',
          displayText: 'Product Categories',
          icon: <CategoryIcon />,
          active: activePath === 'categories',
        },
        {
          key: 'products',
          displayText: 'Products',
          icon: <LocalFloristIcon />,
          active: activePath === 'products',
        },
      ],
    },
    // {
    //   key: 'me',
    //   displayText: 'My Profile',
    //   icon: <AccountCircleIcon />,
    //   active: activePath === 'me',
    // },
    // {
    //   key: 'settings',
    //   displayText: 'Settings',
    //   icon: <SettingsIcon />,
    //   active: activePath === 'settings',
    // },
  ]);

  const handleMenuNavigation = (pageKey: string) => {
    handleDetailsDrawerClose();

    //1. Get a deep copy to work with
    const pagesCopy = _.cloneDeep(pages);

    //2. Find Page to Mark Active
    let pageToMarkActive = pagesCopy.find((p) => p.key === pageKey);

    if (!pageToMarkActive) {
      //search children
      pagesCopy.forEach((parentPage) => {
        if (Object.hasOwn(parentPage, 'children')) {
          //check children
          pageToMarkActive = (
            parentPage as NavigationItemExpandable
          ).children.find((childPage) => childPage.key === pageKey);
        }
      });
    }

    if (!pageToMarkActive) return;

    pageToMarkActive.active = true;

    //3. Find the Previous Active Page and mark it as Inactive
    pagesCopy.forEach((parentPage) => {
      if (Object.hasOwn(parentPage, 'children')) {
        (parentPage as NavigationItemExpandable).children.forEach(
          (nestedPage) => {
            if (nestedPage.key !== pageKey) nestedPage.active = false;
          },
        );
      } else {
        if (parentPage.key !== pageKey) parentPage.active = false;
      }
    });

    //4. Set the pages to updated list
    setPages(pagesCopy);
    navigate(pageKey);
  };

  const handleMenuChange = () => {
    setMenuOpen(!menuOpen);
    setDetailsDrawerConfig({ ...detailsDrawerConfig, open: false });
  };

  //Details Drawer -- Each page has their own version, but we
  //need to share a config for the open variable because it
  //impacts our sidebar nav menu state. This state is
  //shared through the AdminLayoutProvider
  const [detailsDrawerConfig, setDetailsDrawerConfig] =
    useState<DetailsDrawerConfig>({
      open: false,
      width: detailsDrawerWidth,
    });

  const [activeRecord, setActiveRecord] = useState<ViewDto | any>();

  const handleTableRowClick = useCallback(
    <ViewDto,>(data: ViewDto) => {
      if (activeRecord) {
        setDetailsDrawerConfig((prev) => ({ ...prev, open: false }));
        setActiveRecord(undefined);
      } else {
        setActiveRecord(data);
        setMenuOpen(false);
        setDetailsDrawerConfig((prev) => ({ ...prev, open: true }));
      }
    },
    [activeRecord],
  );

  const handleDetailsDrawerClose = useCallback(() => {
    setActiveRecord(undefined);
    setDetailsDrawerConfig({ ...detailsDrawerConfig, open: false });
  }, []);

  const handleMenuItemExpand = (page: NavigationItemExpandable) => {
    const pagesCopy = pages.map((p) => {
      if (p.key === page.key && Object.hasOwn(p, 'open')) {
        return {
          ...p,
          open: !page.open,
        };
      } else {
        return {
          ...p,
        };
      }
    });

    setPages(pagesCopy);
  };

  return (
    <AdminLayoutProvider
      handleTableRowClick={handleTableRowClick}
      handleDetailsDrawerClose={handleDetailsDrawerClose}
      setDetailsDrawerConfig={setDetailsDrawerConfig}
      activeRecord={activeRecord}
      detailsDrawerConfig={detailsDrawerConfig}
    >
      <Grid container alignContent="flex-start">
        <AdminAppBarHeader
          title={pages?.find((p) => p.active)?.displayText || ''}
          menuOpen={menuOpen}
          menuDrawerWidth={menuDrawerWidth}
          detailsDrawerWidth={detailsDrawerConfig.width}
          detailsDrawerOpen={detailsDrawerConfig.open}
          onMenuChange={handleMenuChange}
        />
        <AdminSidebarNav
          menuOpen={menuOpen}
          drawerWidth={menuDrawerWidth}
          onMenuChange={handleMenuChange}
          onMenuNavigation={handleMenuNavigation}
          onMenuItemExpand={handleMenuItemExpand}
          pages={pages}
        />
        <Box
          component="main"
          sx={{
            mt: '100px',
            mb: '60px',
            width: 'calc(100% - 95px)',
            ml: `${menuOpen ? menuDrawerWidth * 1.1 : 95}px`,
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Grid>
    </AdminLayoutProvider>
  );
}
