import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRowsPerPageDefault, TableState } from '../../lib/util/table-util';
import { useDebounce } from 'use-debounce';
import useModal from '../../components/ui/modal/useModal';
import { useAdminLayoutContext } from '../../components/layout/context/AdminLayoutContext';
import useAuth from '../../lib/api/auth/useAuth';
import { Navigate } from 'react-router-dom';
import DetailsDrawer from '../../components/ui/drawer/DetailsDrawer';
import { ViewOrderDto } from '../../lib/api/orders/dto/view-order.dto';
import {
  getAllOrders,
  OrderQueryKeys,
} from '../../lib/api/orders/orders.service';
import OrdersTableInternal from '../../components/orders/OrdersTableInternal';
import OrderDrawerContent from '../../components/orders/order-drawer-content/OrderDrawerContent';
import { Button, Stack } from '@mui/material';
import FullScreenModal from '../../components/ui/modal/FullScreenModal';
import DateRangeForm from '../../components/ui/forms/DateRangeForm';
import type { DateRange } from 'mui-daterange-picker-plus';
import {
  downloadOrdersBetweenReport,
  downloadOrdersDetailsBetweenReport,
  downloadSageSalesOrderBetweenReport,
  downloadShippedCountsBetweenReport,
} from '../../lib/common/report-download.service';

export default function OrdersPageInternal() {
  const {
    handleTableRowClick,
    handleDetailsDrawerClose,
    detailsDrawerConfig,
    activeRecord,
  } = useAdminLayoutContext<ViewOrderDto>();

  const { authed } = useAuth();

  const [tableState, setTableState] = useState<TableState<ViewOrderDto>>({
    pagination: { current: 1, pageSize: getRowsPerPageDefault() },
    filters: null,
    sorter: null,
  });

  const [tableStateDebounced] = useDebounce(tableState, 500);

  const { isError, data, error, isFetching } = useQuery(
    [OrderQueryKeys.findAllOrders, tableStateDebounced],
    () => getAllOrders(tableStateDebounced),
  );

  console.log({
    component: 'OrdersPage',
    message: 'rendered',
    data: data,
  });

  if (!authed()) return <Navigate to="/auth/login" />;

  return (
    <>
      <OrdersTableInternal
        apiRes={data}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={handleTableRowClick}
      />
      <OrdersActionBlock />
      {activeRecord && (
        <DetailsDrawer
          open={detailsDrawerConfig.open}
          detailsDrawerWidth={detailsDrawerConfig.width}
          onDrawerClose={handleDetailsDrawerClose}
          title={activeRecord.id}
        >
          <OrderDrawerContent orderId={activeRecord.id} />
        </DetailsDrawer>
      )}
    </>
  );
}

function OrdersActionBlock() {
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const [reportToDownload, setReportToDownload] = useState<
    'orders' | 'orders-details' | 'shipped-counts' | 'sage-sales-order'
  >();

  const handleDownloadOrders = () => {
    setReportToDownload('orders');
    handleLaunchModal();
  };

  const handleDownloadOrdersDetails = () => {
    setReportToDownload('orders-details');
    handleLaunchModal();
  };

  const handleDownloadShippedCounts = () => {
    setReportToDownload('shipped-counts');
    handleLaunchModal();
  };

  const handleDownloadSageSalesOrder = () => {
    setReportToDownload('sage-sales-order');
    handleLaunchModal();
  };

  const handleSubmit = async (dateRange: DateRange) => {
    handleCloseModal();

    const startDate = dateRange.startDate.toISOString().split('T')[0];
    const endDate = dateRange.endDate.toISOString().split('T')[0];

    if (reportToDownload === 'orders') {
      await downloadOrdersBetweenReport(startDate, endDate);
    } else if (reportToDownload === 'orders-details') {
      await downloadOrdersDetailsBetweenReport(startDate, endDate);
    } else if (reportToDownload === 'shipped-counts') {
      await downloadShippedCountsBetweenReport(startDate, endDate);
    } else if (reportToDownload === 'sage-sales-order') {
      await downloadSageSalesOrderBetweenReport(startDate, endDate);
    }
  };

  return (
    <>
      <Stack direction="row" columnGap={2} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadOrders}
        >
          Download Orders
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadOrdersDetails}
        >
          Download Orders (Details)
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadShippedCounts}
        >
          Download Shipped Counts
        </Button>
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadSageSalesOrder}
        >
          Download Sage Sales Order Data
        </Button> */}
      </Stack>
      <FullScreenModal
        title="Select Date Range for Report"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <DateRangeForm
          description="Select the date range for the Order Weeks you want to include in the report."
          btnText="Download Report"
          onSubmit={handleSubmit}
        />
      </FullScreenModal>
    </>
  );
}
