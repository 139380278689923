import { Expose } from 'class-transformer';
import { IsEmail, IsOptional, IsPhoneNumber, IsString } from 'class-validator';
import CustomMaxLength from '../../../common/custom-class-validators/CustomMaxLength';

export class CreateOrUpdateContactDto {
  @Expose()
  @IsOptional()
  @IsString()
  @CustomMaxLength(25, 'First Name')
  firstName?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @CustomMaxLength(25, 'Last Name')
  lastName?: string;

  @Expose()
  @IsOptional()
  @IsEmail()
  email?: string;

  @Expose()
  @IsOptional()
  @IsPhoneNumber('US')
  mobilePhone?: string;

  @Expose()
  @IsOptional()
  @IsPhoneNumber('US')
  officePhone?: string;

  @Expose()
  @IsOptional()
  @IsPhoneNumber('US')
  fax?: string;
}
