import { useQuery } from '@tanstack/react-query';
import {
  DeliveryRouteQueryKeys,
  getDeliveryRouteById,
} from '../../../lib/api/delivery-routes/delivery-routes.service';
import DeliveryRouteDetailsTab from './DeliveryRouteDetailsTab';
import TabGroup from './../../ui/drawer/DetailsDrawerContent';
import useTabs from '../../ui/tabs/useTabs';

interface DrawerContentProps {
  deliveryRouteId: number;
}

export default function DeliveryRouteDrawerContent({
  deliveryRouteId,
}: DrawerContentProps) {
  const {
    isError,
    data: deliveryRoute,
    error,
    isFetching,
  } = useQuery(
    [DeliveryRouteQueryKeys.findDeliveryRouteById, { id: deliveryRouteId }],
    () => getDeliveryRouteById(deliveryRouteId),
  );

  const { tabValue, handleChangeTab } = useTabs();

  if (!deliveryRoute) return null;

  return (
    <TabGroup tabValue={tabValue} onTabChange={handleChangeTab}>
      <DeliveryRouteDetailsTab
        label="Details"
        tabValue={tabValue}
        index={0}
        deliveryRoute={deliveryRoute}
      />
    </TabGroup>
  );
}
