import { Grid, Typography } from '@mui/material';
import { ViewCategoryDto } from '../../../lib/api/categories/dto/view-category.dto';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import RhfSwitch from '../../ui/forms/RhfSwitch';
import RhfAutocomplete from '../../ui/forms/RhfAutocomplete';
import { useFormContext } from 'react-hook-form';
import RhfCurrencyInput from '../../ui/forms/RhfCurrencyInput';
import { ViewProductDto } from '../../../lib/api/products/dto/view-product.dto';
import RhfTextField from '../../ui/forms/RhfTextField';
import RhfIntegerInput from '../../ui/forms/RhfIntegerInput';
import RhfSkuField from '../../ui/forms/RhfSkuField';
import { SageUom } from '../../../lib/api/products/enums/sage-uoms.enum';

interface Props {
  product: ViewProductDto;
  categories?: ViewCategoryDto[];
}

export default function UpdateProductFormDetailsPartial({
  product,
  categories,
}: Props) {
  const { control } = useFormContext<CreateProductDto>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          General Info
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <RhfTextField control={control} name="name" label="Name" required />
      </Grid>
      {categories && (
        <Grid item xs={12} sm={12} md={5}>
          <RhfAutocomplete
            control={control}
            name="categoryId"
            defaultText="Choose a Category"
            optionsAsArray={categories}
            required
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <RhfTextField
          name="description"
          label="Description"
          control={control}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <RhfSkuField
          control={control}
          name="sku"
          label={product.hasVariants ? 'Base SKU' : 'SKU'}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RhfTextField control={control} name="upc" label="UPC" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RhfTextField control={control} name="itemNo" label="Item #" />
      </Grid>
      <Grid item xs={6} sm={product.hasVariants ? 3 : 4}>
        <RhfIntegerInput
          control={control}
          name="qtyPerShelf"
          label="Qty/Shelf"
          required
        />
      </Grid>
      <Grid item xs={6} sm={product.hasVariants ? 3 : 4}>
        <RhfIntegerInput
          control={control}
          name="shelvesPerRack"
          label="Shelves/Rack"
          required
        />
      </Grid>
      <Grid item xs={6} sm={product.hasVariants ? 3 : 4}>
        <RhfCurrencyInput
          control={control}
          name="price"
          label="Price"
          required
        />
      </Grid>

      <Grid item xs={6} sm={product.hasVariants ? 3 : 4}>
        <RhfAutocomplete
          control={control}
          name="sageUom"
          defaultText="Choose Sage UOM"
          optionsAsEnum={SageUom}
          displayTextFn={(option) => option}
          required
        />
      </Grid>

      {!product.hasVariants && (
        <Grid item xs={6} sm={3} sx={{ mt: 3 }}>
          <RhfSwitch<CreateProductDto>
            label="Proven Winner"
            name="isProvenWinner"
            control={control}
          />
        </Grid>
      )}
    </Grid>
  );
}
