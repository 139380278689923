import { Expose } from 'class-transformer';
import {
  IsBoolean,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsPositive,
  MaxLength,
  MinLength,
} from 'class-validator';

export class UpdateVariantDto {
  @MinLength(3, { message: 'Name must have at least 3 characters' })
  @MaxLength(100, { message: 'Name must not have more than 100 characters' })
  @IsNotEmpty({ message: 'Name must be provided' })
  @IsOptional()
  @Expose()
  name?: string;

  @IsOptional()
  @MaxLength(255, {
    message: 'Description must be no more than 255 characters',
  })
  @Expose()
  description?: string;

  @IsOptional()
  @MinLength(3, { message: 'Item # must have at least 3 characters' })
  @MaxLength(20, { message: 'Item # must not have more than 20 characters' })
  @Expose()
  itemNo?: string;

  @MinLength(3, { message: 'SKU must have at least 3 characters' })
  @MaxLength(255, { message: 'SKU must not have more than 255 characters' })
  @IsNotEmpty({ message: 'SKU must be provided' })
  @Expose()
  sku: string;

  @IsOptional()
  @IsInt({ message: 'Order Limit must be a whole number when provided' })
  @IsPositive({ message: 'Order Limit must be positive when provided' })
  @Expose()
  orderLimit?: number;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isProvenWinner: boolean;

  @IsBoolean()
  @Expose()
  isSellable: boolean;

  @IsBoolean()
  @Expose()
  sellToPopes: boolean;

  @IsBoolean()
  @Expose()
  sellToPremium: boolean;

  @IsBoolean()
  @Expose()
  sellToStandard: boolean;
}
