import { Expose, Type } from 'class-transformer';
import { DefaultIncrementViewDto } from '../../../common/dto/default-increment-view.dto';
import { ViewVariantProductOptionValueDto } from './view-variant-product-option-value.dto';
import { ViewVariantProductOptionDto } from './view-variant-product-option.dto';

export class ViewVariantOptionDto extends DefaultIncrementViewDto {
  @Expose() variantId: string;
  @Expose() optionId: number;
  @Expose() optionValueId: number;

  @Type(() => ViewVariantProductOptionDto)
  @Expose()
  option: ViewVariantProductOptionDto;

  @Type(() => ViewVariantProductOptionValueDto)
  @Expose()
  optionValue: ViewVariantProductOptionValueDto;
}
