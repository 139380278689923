import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  getUserById,
  UserQueryKeys,
} from '../../../lib/api/users/users.service';
import UserDetailsTab from './UserDetailsTab';

interface UserDrawerContentProps {
  userId: string;
}

function tabProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function UserDrawerContent({ userId }: UserDrawerContentProps) {
  const [tabValue, setTabValue] = useState(0);

  const {
    isError,
    data: user,
    error,
    isFetching,
  } = useQuery([UserQueryKeys.findUserById, { id: userId }], () =>
    getUserById(userId),
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Tabs for user details."
          variant="fullWidth"
        >
          <Tab label="Details" {...tabProps(0)} />
        </Tabs>
      </Box>
      {user && (
        <>
          <UserDetailsTab tabValue={tabValue} index={0} user={user} />
        </>
      )}
    </Box>
  );
}
