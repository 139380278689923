import { Expose, Type } from 'class-transformer';
import { ViewVariantDto } from '../../variants/dto/view-variant.dto';
import { ViewProductWithCategoryDto } from '../../products/dto/view-product-with-category.dto';
import ViewCartItemDto from './view-cart-item.dto';

export default class ViewCartItemExtendedDto extends ViewCartItemDto {
  @Expose()
  @Type(() => ViewProductWithCategoryDto)
  product?: ViewProductWithCategoryDto;

  @Expose()
  @Type(() => ViewVariantDto)
  variant?: ViewVariantDto;
}
