import { Expose } from 'class-transformer';
import { IsOptional, IsPhoneNumber, IsString } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';

export class CreateOrUpdateProfileDto {
  @IsString()
  @CustomLength(1, 50, 'First Name')
  @CustomIsNotEmpty('First Name')
  @Expose()
  firstName: string;

  @IsString()
  @CustomLength(1, 50, 'Last Name')
  @CustomIsNotEmpty('Last Name')
  @Expose()
  lastName: string;

  @IsOptional()
  @IsPhoneNumber('US', {
    message: `User's Mobile Phone number must be a valid US phone number.`,
  })
  @Expose()
  mobilePhone?: string;

  @IsOptional()
  @IsPhoneNumber('US', {
    message: `User's Office Phone number must be a valid US phone number.`,
  })
  @Expose()
  officePhone?: string;
}
