import { Avatar, Box, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../lib/api/auth/useAuth';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ResetPasswordDto } from '../../lib/api/auth/dto/reset-password.dto';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';
import CustomForm from '../ui/forms/CustomForm';

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const params = useParams();
  const { resetPassword, login } = useAuth();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isSubmitted },
    setError,
  } = useForm<ResetPasswordDto>({
    resolver: classValidatorResolver(ResetPasswordDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: {
      userId: params.userId,
      password: null,
      passwordConfirm: null,
    },
  });

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<ResetPasswordDto>();

  const onSubmit: SubmitHandler<ResetPasswordDto> = async (
    resetPasswordDto: ResetPasswordDto,
  ) => {
    clearFormSubmitError();
    try {
      const resetRes = await resetPassword(resetPasswordDto, params.passcode);

      if (resetRes.status === 201) {
        const user = await login({
          email: resetRes.data.email,
          password: resetPasswordDto.password,
        });

        if (user?.isAdmin) {
          navigate((state as string) || '/admin/orders');
        } else {
          navigate((state as string) || '/store');
        }
      }
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
      formContainerSx={{ maxWidth: '500px' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        gap={3}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h3" variant="h5">
          Reset Password
        </Typography>
      </Box>

      <Box>
        <TextField
          {...register('password')}
          type="password"
          helperText={errors.password && errors.password.message}
          error={errors.password ? true : false}
          label="New Password"
          required
          autoFocus
          margin="normal"
          fullWidth
        />
        <TextField
          {...register('passwordConfirm')}
          type="password"
          helperText={errors.passwordConfirm && errors.passwordConfirm.message}
          error={errors.passwordConfirm ? true : false}
          label="Confirm Password"
          required
          margin="normal"
          fullWidth
        />
        <FromSubmitButton
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
          isValid={isValid}
          text="Reset & Login"
          sx={{ mt: 3, mb: 2 }}
        />
      </Box>
    </CustomForm>
  );
}
