import { Expose } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsArray,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';

export class CreateChildProductOptionValueForNewProductDto {
  @Expose()
  @IsString()
  parentOptionValue: string;

  @Expose()
  @IsArray()
  @ArrayNotEmpty({ message: 'Option must have at least one value.' })
  @MinLength(1, { each: true })
  @MaxLength(100, { each: true })
  values: string[];
}
