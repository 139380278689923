import { Box, Chip, Grid, Typography } from '@mui/material';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import RhfSwitch from '../../ui/forms/RhfSwitch';
import { useFormContext } from 'react-hook-form';
import RhfTextField from '../../ui/forms/RhfTextField';
import RhfSkuField from '../../ui/forms/RhfSkuField';
import { ViewProductExtendedDto } from '../../../lib/api/products/dto/view-product-extended.dto';
import { ViewVariantExtendedDto } from '../../../lib/api/variants/dto/view-variant-extended.dto';

interface Props {
  variant: ViewVariantExtendedDto;
  product: ViewProductExtendedDto;
}

export default function UpdateVariantFormDetailsPartial({
  product,
  variant,
}: Props) {
  const { control } = useFormContext<CreateProductDto>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          General Info
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="p" sx={{ fontWeight: '700', mb: 1 }}>
          Product:
          <Typography component="span" sx={{ ml: 1 }}>
            {product.name}
          </Typography>
        </Typography>
        <Typography component="p" sx={{ fontWeight: '700' }}>
          Options:
        </Typography>
        <Box display="flex" gap={2} sx={{ pt: 1, pb: '1' }}>
          {variant?.options?.map((variantOption) => (
            <Box key={variantOption.id} textAlign="center">
              <Typography component="p" sx={{ mb: 1 }}>
                {variantOption.option.name}
              </Typography>
              <Chip variant="filled" label={variantOption.optionValue.value} />
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <RhfTextField control={control} name="name" label="Name" required />
      </Grid>

      <Grid item xs={12}>
        <RhfTextField
          name="description"
          label="Description"
          control={control}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <RhfSkuField control={control} name="sku" label="SKU" required />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RhfTextField control={control} name="itemNo" label="Item #" />
      </Grid>

      <Grid item xs={6} sm={6} md={3} sx={{ mt: 3 }}>
        <RhfSwitch<CreateProductDto>
          label="Proven Winner"
          name="isProvenWinner"
          control={control}
        />
      </Grid>
    </Grid>
  );
}
