import { Button, Grid, TextField } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import RhfTagInput from '../../ui/forms/RhfTagInput';
import ChildrenProductOptionsArray from './ChildrenProductOptionsArray';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIconButton from '../../ui/buttons/DeleteIconButton';
import { CreateProductDto } from '../../../lib/api/products/dto/create-product.dto';
import { useEffect } from 'react';

export default function ProductOptionsArray() {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setFocus,
  } = useFormContext<CreateProductDto>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  useEffect(() => {
    try {
      // @ts-ignore
      setTimeout(() => setFocus('options[0].name'), 100);
    } catch (err) {}
  }, [setFocus]);

  const options = watch().options;

  const totalOptions = options
    .map((o: any) => (o?.children?.length || 0) + 1)
    .reduce((n: number, acc: number) => acc + n, 0);

  const childOptions = options.reduce(
    (prev, curr) => prev + curr.children.length,
    0,
  );

  const showAddProductOpt = totalOptions < 3 && childOptions === 0;

  return (
    <Grid item xs={12}>
      {fields.map((item, index) => (
        <Grid key={item.id} container spacing={2}>
          <Grid item xs={4}>
            <TextField
              {...register(`options.${index}.name` as const)}
              autoComplete="off"
              helperText={errors?.options?.[index]?.name?.message || ''}
              error={errors?.options?.[index]?.name ? true : false}
              label="Option Name"
              variant="outlined"
              margin="normal"
              placeholder="Variety"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <RhfTagInput
              control={control}
              name={`options.${index}.values`}
              defaultText="Option Values (Press Enter after each value)"
            />
          </Grid>
          <Grid item xs={2}>
            <DeleteIconButton onClick={() => remove(index)} />
          </Grid>
          <ChildrenProductOptionsArray
            nestIndex={index}
            totalOptions={totalOptions}
          />
        </Grid>
      ))}
      {showAddProductOpt && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              type="button"
              onClick={() => {
                append({ name: '', values: [], children: [] });
              }}
              variant="contained"
              color="secondary"
              startIcon={<AddCircleOutlineIcon />}
              sx={{ mt: 2 }}
            >
              Add Product Option
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
