import { Grid } from '@mui/material';
import logo from '../../../assets/logo.png';

export default function Header() {
  return (
    <Grid
      item
      xs={12}
      mb={1}
      sx={{
        fontFamily: "'Contrail One', 'Raleway', sans-serif",
        textAlign: 'center',
        padding: '1rem',
      }}
    >
      <img src={logo} style={{ height: '75px' }} alt="Popes Plant Farm Logo" />
    </Grid>
  );
}
