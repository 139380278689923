import EditIcon from '@mui/icons-material/Edit';
import IconButtonBase from './IconButtonBase';

interface Props {
  onClick: () => any;
  resource?: string;
  size?: 'small' | 'medium' | 'large';
}

export default function EditIconButton({ onClick, resource, size }: Props) {
  const accessMsg = `Edit ${resource || ''}`.trim();

  return (
    <IconButtonBase onClick={onClick} tabIndex={-1} size={size || 'medium'}>
      <EditIcon aria-label={accessMsg} titleAccess={accessMsg} />
    </IconButtonBase>
  );
}
