import { Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { useAppContext } from '../../contexts/AppContext';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import {
  CustomerType,
  ViewCustomerDto,
} from '../../lib/api/customers/dto/view-customer.dto';
import {
  createCustomer,
  CustomerQueryKeys,
  updateCustomer,
} from '../../lib/api/customers/customers.service';
import { CreateOrUpdateCustomerDto } from '../../lib/api/customers/dto/create-or-update-customer.dto';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import RhfAutocomplete from '../ui/forms/RhfAutocomplete';
import RhfTextField from '../ui/forms/RhfTextField';
import RhfDecimalInput from '../ui/forms/RhfDecimalInput';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';

interface Props {
  customer?: ViewCustomerDto;
  onSuccess?: onSuccessCallback;
}

export default function AddOrUpdateCustomerForm({
  customer,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();
  const queryClient = useQueryClient();

  const action = customer ? 'Update Customer' : 'Create Customer';

  const defaultValues: CreateOrUpdateCustomerDto = customer
    ? plainToInstance(CreateOrUpdateCustomerDto, instanceToPlain(customer), {
        excludeExtraneousValues: true,
      })
    : {
        discountRate: '0',
        type: CustomerType.Standard,
        name: null,
        notes: null,
      };

  //Mutations
  const createMutation = useMutation(
    (createCustomerDto: CreateOrUpdateCustomerDto) =>
      createCustomer(createCustomerDto),
  );

  const updateMutation = useMutation(
    (updateCustomerDto: CreateOrUpdateCustomerDto) =>
      updateCustomer(customer.id, updateCustomerDto),
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    setError,
    setFocus,
  } = useForm<CreateOrUpdateCustomerDto>({
    resolver: classValidatorResolver(CreateOrUpdateCustomerDto),
    mode: 'onTouched',
    defaultValues,
    delayError: VALIDATION_ERR_DELAY,
  });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateOrUpdateCustomerDto>();

  const onSubmit: SubmitHandler<CreateOrUpdateCustomerDto> = async (
    formData: CreateOrUpdateCustomerDto,
  ) => {
    clearFormSubmitError();

    try {
      let result: ViewCustomerDto;

      if (customer) {
        //Updating
        result = await updateMutation.mutateAsync(formData);
        queryClient.invalidateQueries([
          CustomerQueryKeys.findCustomerById,
          { id: customer.id },
        ]);
      } else {
        //Creating
        result = await createMutation.mutateAsync(formData);
      }

      queryClient.invalidateQueries([CustomerQueryKeys.findAllCustomers]);
      handleShowMessage(
        `${customer ? 'Updated' : 'Created'} Customer '${result.name}'`,
      );
      onSuccess?.(result);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RhfTextField control={control} name="name" label="Name" required />
        </Grid>
        <Grid item xs={12}>
          <RhfAutocomplete
            control={control}
            name="type"
            defaultText="Type"
            optionsAsEnum={CustomerType}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <RhfDecimalInput
            control={control}
            name="discountRate"
            label="Discount %"
            decimals={2}
            isAllowed={(values) =>
              values.floatValue ? values.floatValue < 100 : true
            }
            showPercent={true}
          />
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            name="notes"
            label="Account Notes"
            control={control}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text={action}
          />
        </Grid>
      </Grid>
    </CustomForm>
  );
}
