import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { AnEnum } from '../../../lib/util/type-utils';
import RequiredInputLabel from './RequiredInputLabel';

interface BaseProps {
  control: Control<any, any>;
  name: string;
  defaultText: string;
  required?: boolean;
}

interface PropsWithOptionsArray extends BaseProps {
  optionsAsArray: any[];
  displayTextFn?: (option: any) => string;
}

interface PropsWithOptionsEnum extends BaseProps {
  optionsAsEnum: AnEnum;
}

type Props = PropsWithOptionsArray | PropsWithOptionsEnum;

export default function RhfAutocomplete({
  control,
  name,
  defaultText,
  required,
  ...props
}: Props) {
  let content;

  const optionsAsEnum = (props as unknown as any).optionsAsEnum;
  const optionsAsArray = (props as unknown as any).optionsAsArray;

  if (optionsAsEnum) {
    content = (
      <Controller
        control={control}
        name={name}
        defaultValue={null}
        render={({
          field: { onChange, ref, name, ...props },
          fieldState: { error },
        }) => (
          <Autocomplete
            disablePortal
            options={Object.values(optionsAsEnum)}
            getOptionLabel={(option) => option}
            fullWidth
            autoHighlight
            autoSelect
            onChange={(event, selected) => onChange(selected)}
            {...props}
            renderInput={(params) => (
              <TextField
                name={name}
                inputRef={ref}
                {...params}
                label={
                  required ? (
                    <RequiredInputLabel text={defaultText} />
                  ) : (
                    defaultText
                  )
                }
                margin="normal"
                helperText={error && error.message}
                error={error ? true : false}
              />
            )}
          />
        )}
      />
    );
  }

  if (optionsAsArray) {
    const displayTextFn = (props as PropsWithOptionsArray).displayTextFn;
    content = (
      <Controller
        control={control}
        name={name}
        defaultValue={null}
        render={({
          field: { onChange, ref, name, value, ...props },
          fieldState: { error },
        }) => (
          <Autocomplete
            disablePortal
            options={optionsAsArray}
            getOptionLabel={(option) =>
              displayTextFn ? displayTextFn(option) : option.name
            }
            fullWidth
            autoHighlight
            autoSelect
            onChange={(event, selected) => {
              onChange(selected?.id || null);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={optionsAsArray.find((opt: any) => opt.id === value) || null}
            {...props}
            renderInput={(params) => (
              <TextField
                name={name}
                inputRef={ref}
                {...params}
                label={
                  required ? (
                    <RequiredInputLabel text={defaultText} />
                  ) : (
                    defaultText
                  )
                }
                margin="normal"
                helperText={error && error.message}
                error={error ? true : false}
              />
            )}
          />
        )}
      />
    );
  }

  return content;
}
