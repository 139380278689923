import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useAppContext } from '../../../contexts/AppContext';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import {
  createCustomerShippingAddress,
  CustomerQueryKeys,
} from '../../../lib/api/customers/customers.service';
import { CreateShippingAddressDto } from '../../../lib/api/addresses/dto/create-shipping-address.dto';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { plainToInstance } from 'class-transformer';
import { Grid } from '@mui/material';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import { StepperData } from '../../ui/forms/CustomStepper';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import useMultiStepForm from '../../ui/forms/useMultiStepForm';
import { useState } from 'react';
import MultiStepForm from '../../ui/forms/MultiStepForm';
import MultiStepFormPage from '../../ui/forms/MultiStepFormPage';
import { onSuccessCallback } from '../../ui/forms/CustomForm';
import useModal from '../../ui/modal/useModal';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import ShippingAddressPartial from './ShippingAddressPartial';
import CreateAddressContactPartial from '../CreateAddressContactPartial';
import ShippingAddressDeliveryRoutePartial from './ShippingAddressDeliveryRoutePartial';
import AddOrUpdateDeliveryRouteForm from '../../delivery-routes/AddOrUpdateDeliveryRouteForm';

interface Props {
  onSuccess?: onSuccessCallback;
  customerId: string;
}

export default function AddShippingAddressForm({
  onSuccess,
  customerId,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const createMutation = useMutation(
    (createShippingAddressDto: CreateShippingAddressDto) =>
      createCustomerShippingAddress(customerId, createShippingAddressDto),
  );

  const [steps, setSteps] = useState<StepperData<CreateShippingAddressDto>[]>([
    {
      text: 'Add Adress Info',
      isActive: true,
      fieldsInStep: ['name', 'street1', 'street2', 'city', 'state', 'zip'],
      focusField: 'name',
      fieldsToValidateOnNext: [
        'name',
        'street1',
        'street2',
        'city',
        'state',
        'zip',
      ],
    },
    {
      text: 'Assign Route',
      fieldsInStep: ['routeId'],
      focusField: 'routeId',
      fieldsToValidateOnNext: ['routeId'],
    },
    {
      text: 'Add Contact Info',
      fieldsInStep: [
        'contact.email',
        'contact.firstName',
        'contact.lastName',
        'contact.mobilePhone',
        'contact.officePhone',
        'contact.fax',
      ],
      focusField: 'contact.email',
    },
  ]);

  const form = useMultiStepForm<CreateShippingAddressDto>({
    resolver: classValidatorResolver(CreateShippingAddressDto, {
      forbidUnknownValues: true,
    }),
    defaultValues: {
      type: AddressType.shipping,
      name: null,
      routeId: null,
      street1: null,
      street2: null,
      city: null,
      state: null,
      zip: null,
    },
    steps,
    setSteps,
  });

  const {
    formState: { errors, isSubmitting, isValid, isSubmitted },
    setValue,
    setError,
    handleSubmit,
    handleNextClick,
    handleSkipClick,
    handleBackClick,
    setActivePage,
  } = form;

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateShippingAddressDto>();

  const onSubmit: SubmitHandler<CreateShippingAddressDto> = async (
    formData: CreateShippingAddressDto,
  ) => {
    clearFormSubmitError();

    const createShippingAddressDto = plainToInstance(
      CreateShippingAddressDto,
      formData,
      {
        excludeExtraneousValues: true,
        exposeDefaultValues: false,
        exposeUnsetFields: false,
      },
    );

    try {
      const createdAddress = await createMutation.mutateAsync(
        createShippingAddressDto,
      );
      queryClient.invalidateQueries([
        CustomerQueryKeys.findCustomerById,
        { id: customerId },
      ]);
      handleShowMessage(
        `Created Shipping Address @ '${createdAddress.street1}'`,
      );
      onSuccess?.(createdAddress);
    } catch (err: any) {
      processFormSubmitError(err, setError, { steps, setActivePage });
    }
  };

  const {
    handleCloseModal: handleCloseAddRouteModal,
    handleLaunchModal: handleLaunchAddRouteModal,
    modalOpen: addRouteModalOpen,
  } = useModal();

  const onCreateRouteSuccess = (data: any) => {
    setValue('routeId', data.id);
    handleCloseAddRouteModal();
  };

  return (
    <>
      <FormProvider {...form}>
        <MultiStepForm
          steps={steps}
          handleSubmit={handleSubmit(onSubmit)}
          serverErrorMessage={serverErrorMessage}
        >
          <MultiStepFormPage
            step={steps[0]}
            onNext={handleNextClick}
            errors={errors}
          >
            <ShippingAddressPartial />
          </MultiStepFormPage>
          <MultiStepFormPage
            step={steps[1]}
            onNext={handleNextClick}
            onBack={handleBackClick}
            onSkip={handleSkipClick}
            errors={errors}
          >
            <ShippingAddressDeliveryRoutePartial
              onAddRouteClick={handleLaunchAddRouteModal}
            />
          </MultiStepFormPage>
          <MultiStepFormPage
            step={steps[2]}
            onBack={handleBackClick}
            errors={errors}
          >
            <CreateAddressContactPartial />
            <Grid item xs={12}>
              <FromSubmitButton
                isSubmitted={isSubmitted}
                isSubmitting={isSubmitting}
                isValid={isValid}
                text="Create Shipping Address"
              />
            </Grid>
          </MultiStepFormPage>
        </MultiStepForm>
      </FormProvider>

      <FullScreenModal
        title="Create Delivery Route"
        open={addRouteModalOpen}
        onClose={handleCloseAddRouteModal}
      >
        <AddOrUpdateDeliveryRouteForm onSuccess={onCreateRouteSuccess} />
      </FullScreenModal>
    </>
  );
}
