import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import CartActions from '../../components/cart/CartActions';
import CartTable from '../../components/cart/CartTable';
import ExpiredAlert from '../../components/cart/ExpiredAlert';
import OrderMinimumMsg from '../../components/cart/OrderMinimumMsg';
import { useCartContext } from '../../contexts/CartContext';
import useAuth from '../../lib/api/auth/useAuth';

export default function CartPage() {
  const { authed, loggedInUser } = useAuth();
  const { isExpired, isPreviousOrderWeek, cart, storeStatus } =
    useCartContext();

  if (!authed()) return <Navigate to="/auth/login" />;

  const checkoutIsDisabled = () => {
    if (isExpired) return true;

    return false;
  };

  const showOrderMinimumMsg = !(loggedInUser.isAdmin || loggedInUser.isRetail);

  return (
    <>
      {(isExpired || isPreviousOrderWeek) && (
        <Box sx={{ maxWidth: '90%', mt: 2, mb: 2 }}>
          <ExpiredAlert />
        </Box>
      )}
      <CartTable />
      <Box sx={{ mt: 3 }}>
        {showOrderMinimumMsg && (
          <OrderMinimumMsg
            orderAmt={cart.productTotalBeforeDiscounts}
            min={storeStatus.minimumOrderValue}
          />
        )}
      </Box>

      <CartActions checkoutDisabled={checkoutIsDisabled()} />
    </>
  );
}
