import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../assets/logo.png';

interface AppBarProps extends MuiAppBarProps {
  menuOpen?: boolean;
  detailsDrawerOpen?: boolean;
  menuDrawerWidth: number;
  detailsDrawerWidth: number;
}

interface AppBarHeaderProps {
  title: string;
  menuOpen: boolean;
  detailsDrawerOpen: boolean;
  menuDrawerWidth: number;
  detailsDrawerWidth: number;
  onMenuChange: () => any;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'menuOpen' &&
    prop !== 'menuDrawerWidth' &&
    prop !== 'detailsDrawerOpen' &&
    prop !== 'detailsDrawerWidth',
})<AppBarProps>(
  ({
    theme,
    menuOpen,
    detailsDrawerOpen,
    menuDrawerWidth,
    detailsDrawerWidth,
  }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    zIndex: theme.zIndex.appBar,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(menuOpen && {
      marginLeft: menuDrawerWidth,
      width: `calc(100% - ${menuDrawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(detailsDrawerOpen && {
      marginRight: `min(100%,${detailsDrawerWidth}px)`,
      width: `calc(100% - min(100%,${detailsDrawerWidth}px))`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& .app-bar-header-company-logo': {
        width: '0%',
      },
    }),
  }),
);

export default function AdminAppBarHeader({
  title,
  menuOpen,
  detailsDrawerOpen,
  menuDrawerWidth,
  detailsDrawerWidth,
  onMenuChange,
}: AppBarHeaderProps) {
  return (
    <AppBar
      position="fixed"
      menuOpen={menuOpen}
      menuDrawerWidth={menuDrawerWidth}
      detailsDrawerOpen={detailsDrawerOpen}
      detailsDrawerWidth={detailsDrawerWidth}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onMenuChange}
          edge="start"
          sx={{
            marginRight: 5,
            ...(menuOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Typography component="h1" variant="h4" noWrap style={{ flex: 1 }}>
          {title}
        </Typography>
        <img
          className="app-bar-header-company-logo"
          src={logo}
          style={{ height: '60px', padding: '0.25rem', maxWidth: '90%' }}
          alt="Popes Plant Farm Logo"
        />
      </Toolbar>
    </AppBar>
  );
}
