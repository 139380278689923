import { Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { ViewShippingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-shipping-address-extended.dto';
import { ViewCustomerDto } from '../../../lib/api/customers/dto/view-customer.dto';
import { DeleteCustomerAddressConfirmDialog } from '../DeleteCustomerAddressConfirmDialog';
import Empty from '../../ui/custom-icons/Empty';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import useModal from '../../ui/modal/useModal';
import AddressListItem from './AddressListItem';
import UpdateBillingAddressForm from '../billing-address/UpdateBillingAddressForm';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import UpdateShippingAddressForm from '../shipping-address/UpdateShippingAddressForm';
import { toProperCase } from '../../../lib/util/misc-utils';
import { ViewBillingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-billing-address-extended.dto';

interface Props {
  addresses: Array<
    ViewBillingAddressExtendedDto | ViewShippingAddressExtendedDto
  >;
  customer: ViewCustomerDto;
  title?: string;
}

export default function AddressList({ customer, addresses, title }: Props) {
  const {
    handleCloseModal: handleCloseEditModal,
    handleLaunchModal: handleLaunchEditModal,
    modalOpen: editModalOpen,
  } = useModal();

  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const [addressToEdit, setAddressToEdit] = useState<
    ViewBillingAddressExtendedDto | ViewShippingAddressExtendedDto
  >(null);
  const [addressToDelete, setAddressToDelete] = useState<
    ViewBillingAddressExtendedDto | ViewShippingAddressExtendedDto
  >(null);

  if (addresses.length === 0) return <Empty />;

  const onEditAddressSuccess = () => {
    handleCloseEditModal();
  };

  const handleEditAddress = (id: string) => {
    setAddressToEdit(addresses.find((a) => a.id === id));
    handleLaunchEditModal();
  };

  const handleDeleteAddress = (id: string) => {
    setAddressToDelete(addresses.find((a) => a.id === id));
    handleOpenConfirmDialog();
  };

  return (
    <>
      <Stack
        spacing={2}
        divider={
          <Divider
            orientation="horizontal"
            variant="middle"
            sx={{ borderBottomWidth: 3 }}
          />
        }
        title={title}
      >
        {addresses.map((address) => (
          <AddressListItem
            key={address.id}
            address={address}
            customerName={customer.name}
            onDelete={handleDeleteAddress}
            onEdit={handleEditAddress}
          />
        ))}
      </Stack>

      {addressToDelete && (
        <DeleteCustomerAddressConfirmDialog
          address={addressToDelete}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
        />
      )}

      {addressToEdit && (
        <FullScreenModal
          title={`Update ${toProperCase(addressToEdit.type)} Address for ${
            customer.name
          }`}
          open={editModalOpen}
          onClose={handleCloseEditModal}
        >
          {addressToEdit.type === AddressType.billing && (
            <UpdateBillingAddressForm
              address={addressToEdit}
              onSuccess={onEditAddressSuccess}
            />
          )}

          {addressToEdit.type === AddressType.shipping && (
            <UpdateShippingAddressForm
              address={addressToEdit}
              onSuccess={onEditAddressSuccess}
            />
          )}
        </FullScreenModal>
      )}
    </>
  );
}
