import { TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useAppContext } from '../../../contexts/AppContext';
import { getErrorMessage } from '../../../lib/util/misc-utils';
import {
  InventoryQueryKeys,
  removeInventoryItemManualQty,
  updateInventoryItem,
} from '../../../lib/api/inventory/inventory.service';
import { InventoryWithCalculatedFields } from '../table/InventoryAvailabilityTable';
import { UpdateInventoryItemManualQtyDto } from '../../../lib/api/inventory/dto/udpate-inventory-item-manual-qty.dto';

interface Props {
  name: string;
  label: string;
  decimals?: number;
  item: InventoryWithCalculatedFields;
}

export default function ManualQtyInput({ name, label, decimals, item }: Props) {
  if (!decimals) decimals = 2;

  const [error, setError] = useState(null);

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();

  const updateManualQtyMutation = useMutation(
    (updateInventoryItemDto: UpdateInventoryItemManualQtyDto) =>
      updateInventoryItem(updateInventoryItemDto),
  );

  const removeManualQtyMutation = useMutation(
    ({ id, type }: { id: string; type: 'product' | 'variant' }) =>
      removeInventoryItemManualQty(id, type),
  );

  const updateManualQty = async (value: string) => {
    setError(null);

    //No Change - don't submit to server
    if (value === item.manualQty?.toFixed(2)) return;

    //If we have a blank value and we have not completed the manual
    //qty for the week, don't bother clearing it out.
    if (value === '' && !item.manualQtyForWeekComplete) return;

    try {
      if (value === '' || value === null) {
        //Blank value -- Remove the Manual Qty
        let type: 'product' | 'variant' = item.variantId
          ? 'variant'
          : 'product';
        let id = item.variantId || item.productId;

        const res = await removeManualQtyMutation.mutateAsync({ id, type });

        if (item.variantId) {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findVariantsInventoryByProductId,
            { id: item.productId },
          ]);
        } else {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findInventoryForSimpleProductsByCategoryId,
            { id: res.categoryId },
          ]);
        }
      } else {
        //Update based on the value
        let updateObj: UpdateInventoryItemManualQtyDto;

        if (item.variantId) {
          updateObj = {
            variantId: item.variantId,
            manualQty: value,
          };
        } else {
          updateObj = {
            productId: item.productId,
            manualQty: value,
          };
        }
        const res = await updateManualQtyMutation.mutateAsync(updateObj);

        if (item.variantId) {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findVariantsInventoryByProductId,
            { id: item.productId },
          ]);
        } else {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findInventoryForSimpleProductsByCategoryId,
            { id: res.categoryId },
          ]);
        }
      }
    } catch (err: any) {
      setError(getErrorMessage(err));
    }
  };

  return (
    <NumericFormat
      name={name}
      allowNegative={true}
      decimalScale={decimals}
      thousandSeparator=","
      customInput={TextField}
      helperText={error || null}
      error={error ? true : false}
      label={label}
      variant="outlined"
      size="small"
      onBlur={(e) => updateManualQty(e.currentTarget.value)}
      value={item.manualQtyForWeekComplete ? item.manualQty.toFixed(2) : ''}
      defaultValue=""
      margin="normal"
      fullWidth
    />
  );
}
