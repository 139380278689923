import { Expose, Type } from 'class-transformer';
import {
  IsBoolean,
  IsEnum,
  IsInt,
  IsOptional,
  IsPositive,
  Length,
  Min,
  ValidateNested,
} from 'class-validator';
import { ProductLocation } from '../enums/product-locations.enum';
import { CreateProductOptionForNewProductDto } from '../../product-options/dto/create-product-option-for-new-product.dto';
import { NumericStringMin } from '../../../common/custom-class-validators/NumericStringMin';
import { IsNumericString } from '../../../common/custom-class-validators/IsNumericString';
import CustomMaxLength from '../../../common/custom-class-validators/CustomMaxLength';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';
import { SageUom } from '../enums/sage-uoms.enum';

export class CreateProductDto {
  @IsBoolean()
  @Expose()
  hasVariants: boolean;

  @CustomLength(3, 100, 'Name')
  @CustomIsNotEmpty('Name')
  @Expose()
  name: string;

  @IsOptional()
  @CustomMaxLength(255, 'Description')
  @Expose()
  description?: string;

  @IsOptional()
  @Length(12, 12, { message: 'UPC must be 12 characters' })
  @Expose()
  upc?: string;

  @IsOptional()
  @CustomLength(3, 20, 'Item #')
  @Expose()
  itemNo?: string;

  @CustomLength(3, 50, 'SKU')
  @CustomIsNotEmpty('SKU')
  @Expose()
  sku: string;

  @IsInt({ message: `Qty/Shelf must be a whole number` })
  @Min(1, { message: `Qty/Shelf must be 1 or more` })
  @CustomIsNotEmpty('Qty/Shelf')
  @Expose()
  qtyPerShelf: number;

  @IsInt({ message: `Shelves/Rack must be a whole number` })
  @Min(1, { message: `Shelves/Rack must be 1 or more` })
  @CustomIsNotEmpty('Shelves/Rack')
  @Expose()
  shelvesPerRack: number;

  @IsNumericString(0, 2)
  @NumericStringMin(0, { message: `Price must be positive` })
  @CustomIsNotEmpty('Price')
  @Expose()
  price: string;

  @IsEnum(ProductLocation)
  @Expose()
  location: ProductLocation;

  @IsOptional()
  @IsInt({ message: 'Order Limit must be a whole number when provided' })
  @IsPositive({ message: 'Order Limit must be positive when provided' })
  @Expose()
  orderLimit?: string;

  @IsOptional()
  @IsNumericString()
  @NumericStringMin(0, {
    message: 'Qty on Hand must be 0 or more when provided',
  })
  @Expose()
  qty?: string;

  @IsOptional()
  @IsBoolean()
  @Expose()
  isProvenWinner: boolean;

  @IsBoolean()
  @Expose()
  isSellable: boolean;

  @IsBoolean()
  @Expose()
  sellToPopes: boolean;

  @IsBoolean()
  @Expose()
  sellToPremium: boolean;

  @IsBoolean()
  @Expose()
  sellToStandard: boolean;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => CreateProductOptionForNewProductDto)
  @Expose()
  options?: CreateProductOptionForNewProductDto[];

  @CustomIsNotEmpty('Category')
  @Expose()
  categoryId: number;

  @IsEnum(SageUom)
  @Expose()
  sageUom: SageUom;
}
