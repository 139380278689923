import { Box, Button, Chip, Stack } from '@mui/material';
import useConfirmDialog from '../../ui/dialog/useConfirmDialog';
import useModal from '../../ui/modal/useModal';
import DescriptionContainer from '../../ui/description/DescriptionContainer';
import DescriptionItem from '../../ui/description/DescriptionItem';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import TabPanel from '../../ui/tabs/TabPanel';
import { useAdminLayoutContext } from '../../layout/context/AdminLayoutContext';
import { DeleteProductConfirmDialog } from '../DeleteProductConfirmDialog';
import { ViewProductDto } from '../../../lib/api/products/dto/view-product.dto';
import UpdateProductForm from '../UpdateProductForm/UpdateProductForm';
import CheckIcon from '@mui/icons-material/Check';
import { ViewProductExtendedDto } from '../../../lib/api/products/dto/view-product-extended.dto';
import { usd } from '../../../lib/util/misc-utils';
import ProductOrVariantImageUpload from '../ProductOrVariantImageUpload';

interface Props {
  label: string;
  tabValue: number;
  index: number;
  product: ViewProductExtendedDto;
}
export default function ProductDetailsTab({
  label,
  tabValue,
  index,
  product,
}: Props) {
  const { handleDetailsDrawerClose } = useAdminLayoutContext<ViewProductDto>();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const onUpdateProductSuccess = () => {
    handleCloseModal();
  };

  const onDeleteProductSuccess = () => {
    handleDetailsDrawerClose();
  };

  return (
    <TabPanel value={tabValue} index={index}>
      <DescriptionContainer>
        <DescriptionItem label="Name">{product.name}</DescriptionItem>
        <DescriptionItem label="Cateogry">
          {product?.category?.name || 'Not Assigned'}
        </DescriptionItem>
        <DescriptionItem label="Location">{product.location}</DescriptionItem>

        <DescriptionItem label="id">{product.id}</DescriptionItem>
        <DescriptionItem label={product.hasVariants ? 'Base Sku' : 'Sku'}>
          {product.sku}
        </DescriptionItem>
        <DescriptionItem label="ItemNo">
          {product.itemNo || '--'}
        </DescriptionItem>
        <DescriptionItem label="UPC">{product.upc || '--'}</DescriptionItem>

        <DescriptionItem label="Description">
          {product.description || '--'}
        </DescriptionItem>
        <DescriptionItem label="Qty / Shelf">
          {product.qtyPerShelf}
        </DescriptionItem>
        <DescriptionItem label="Shelves / Rack">
          {product.shelvesPerRack}
        </DescriptionItem>
        <DescriptionItem label="Price">{usd(product.price)}</DescriptionItem>
        <DescriptionItem label="Order Increment">
          {product.orderIncrement.toString()}
        </DescriptionItem>
        <DescriptionItem label="Sage Uom">{product.sageUom}</DescriptionItem>
        {!product.hasVariants && (
          <>
            <DescriptionItem label="Qty">
              {product.qty.toString() || 0}
            </DescriptionItem>
            <DescriptionItem label="Order Limit">
              {product.orderLimit || 0}
            </DescriptionItem>
          </>
        )}

        <DescriptionItem label="Availability">
          <Stack direction="row" spacing={2}>
            <Chip
              label="Sellable"
              color={product.isSellable ? 'primary' : 'default'}
              icon={product.isSellable ? <CheckIcon /> : null}
            />
            <Chip
              label="Popes"
              color={product.sellToPopes ? 'primary' : 'default'}
              icon={product.sellToPopes ? <CheckIcon /> : null}
            />
            <Chip
              label="Premium"
              color={product.sellToPremium ? 'primary' : 'default'}
              icon={product.sellToPremium ? <CheckIcon /> : null}
            />
            <Chip
              label="Standard"
              color={product.sellToStandard ? 'primary' : 'default'}
              icon={product.sellToStandard ? <CheckIcon /> : null}
            />
          </Stack>
        </DescriptionItem>
      </DescriptionContainer>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="contained" onClick={handleLaunchModal}>
          Edit Product
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'secondary.main',
            '&:hover': { backgroundColor: 'secondary.dark' },
          }}
          onClick={handleOpenConfirmDialog}
        >
          Delete Product
        </Button>
      </Box>
      {!product.hasVariants && (
        <ProductOrVariantImageUpload
          productId={product.id}
          defaultImageFileUrl={product.defaultImageFileUrl}
        />
      )}

      <DeleteProductConfirmDialog
        product={product}
        isOpen={confirmDialogIsOpen}
        onClose={handleCloseConfirmDialog}
        onSuccess={onDeleteProductSuccess}
      />
      <FullScreenModal
        title="Update Product"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <UpdateProductForm
          product={product}
          onSuccess={onUpdateProductSuccess}
        />
      </FullScreenModal>
    </TabPanel>
  );
}
