import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AddressContent, {
  AddressFragment,
} from '../../../components/addresses/AddressContent';
import ContactContent, {
  ContactFragment,
} from '../../../components/contacts/ContactContent';
import { AddressType } from '../../../lib/api/addresses/enums/address-type.enum';
import {
  getOrderById,
  OrderQueryKeys,
} from '../../../lib/api/orders/orders.service';
import MapIcon from '@mui/icons-material/Map';
import BadgeIcon from '@mui/icons-material/Badge';
import LineItemsTable from './table/LineItemsTable';
import { OrderType } from '../../../lib/api/orders/dto/create-order.dto';
import { ViewOrderExtendedDto } from '../../../lib/api/orders/dto/view-order-extended.dto';
import useAuth, { AuthenticatedUser } from '../../../lib/api/auth/useAuth';
import {
  createInvoiceAndLoadingListPdf,
  createInvoicePdf,
  createLoadingListPdf,
  createOrderConfirmationPdf,
} from '../../../lib/common/pdf.service';
import useModal from '../../../components/ui/modal/useModal';
import FullScreenModal from '../../../components/ui/modal/FullScreenModal';
import UpdateOrderNoteForm from '../../../components/orders/UpdateOrderNoteForm';
import EditIconButton from '../../../components/ui/buttons/EditIconButton';
import UpdateOrderPOForm from '../../../components/orders/UpdateOrderPOForm';
import useConfirmDialog from '../../../components/ui/dialog/useConfirmDialog';
import { DeleteOrderConfirmDialog } from '../../../components/orders/DeleteOrderConfirmDialog';
import UpdateOrderShipToAddressForm from '../../../components/orders/update-order-ship-to-address-form/UpdateOrderShipToAddressForm';

interface DataItemProps {
  title: string;
  value: string;
  onEdit?: () => void;
}
function DataItem({ title, value, onEdit }: DataItemProps) {
  return (
    <Box display="flex">
      <Typography component="span" sx={{ fontWeight: '700', mr: 1 }}>
        {title}:
      </Typography>
      <Typography component="span">
        <>
          {value}
          {onEdit && (
            <Box sx={{ display: 'inline', ml: 2 }}>
              <EditIconButton onClick={onEdit} resource={title} size="small" />
            </Box>
          )}
        </>
      </Typography>
    </Box>
  );
}

interface Props {
  orderId?: string;
  closeModal?: () => void;
}
export default function OrderConfirmationPage({ orderId, closeModal }: Props) {
  const { id } = useParams();
  const { loggedInUser } = useAuth();

  const {
    isError: isErrorOrder,
    data: order,
    error: orderError,
  } = useQuery([OrderQueryKeys.findOrderById, { id: orderId || id }], () =>
    getOrderById(orderId || id),
  );

  if (!order) return;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Order Confirmation
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <OrderInfoBlock order={order} loggedInUser={loggedInUser} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BillToBlock order={order} loggedInUser={loggedInUser} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ShipToBlock order={order} loggedInUser={loggedInUser} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LineItemsTable order={order} />
      </Grid>
      <Grid item display="flex" justifyContent="flex-start" xs={12}>
        <ActionsBlock
          order={order}
          showNavigateBtn={!orderId}
          closeModal={closeModal}
          loggedInUser={loggedInUser}
        />
      </Grid>
    </Grid>
  );
}

export interface BlockProps {
  order: ViewOrderExtendedDto;
  loggedInUser: AuthenticatedUser;
}

function OrderInfoBlock({ order, loggedInUser }: BlockProps) {
  const {
    handleCloseModal: handleCloseEditOrderNoteModal,
    handleLaunchModal: handleLaunchEditOrderNoteModal,
    modalOpen: editOrderNoteModalOpen,
  } = useModal();

  const {
    handleCloseModal: handleCloseEditPOModal,
    handleLaunchModal: handleLaunchEditPOModal,
    modalOpen: editPOModalOpen,
  } = useModal();

  const onEditOrderNoteSuccess = () => {
    handleCloseEditOrderNoteModal();
  };

  const handleEditOrderNote = () => {
    handleLaunchEditOrderNoteModal();
  };

  const onEditPOSuccess = () => {
    handleCloseEditPOModal();
  };

  const handleEditPO = () => {
    handleLaunchEditPOModal();
  };

  const orderIdToShow = order.id.substring(0, 8);
  const notes = `${order?.note || ''} ${order?.customer?.notes || ''}`.trim();

  return (
    <>
      <DataItem title="Order #" value={orderIdToShow} />
      <DataItem
        title="Placed"
        value={order.createdAt.toFormat('M/d/yy, h:mm a')}
      />
      <DataItem title="Placed By" value={order.placedBy} />
      <DataItem title="Ship Week" value={order.orderWeek} />
      <DataItem title="PO #" value={order.poNumber} onEdit={handleEditPO} />
      <DataItem title="Notes" value={notes} onEdit={handleEditOrderNote} />
      <FullScreenModal
        title={`PO # for ${orderIdToShow}`}
        open={editPOModalOpen}
        onClose={handleCloseEditPOModal}
      >
        <UpdateOrderPOForm order={order} onSuccess={onEditPOSuccess} />
      </FullScreenModal>
      {loggedInUser.isAdmin && (
        <FullScreenModal
          title={`Order Note for ${orderIdToShow}`}
          open={editOrderNoteModalOpen}
          onClose={handleCloseEditOrderNoteModal}
        >
          <UpdateOrderNoteForm
            order={order}
            onSuccess={onEditOrderNoteSuccess}
          />
        </FullScreenModal>
      )}
    </>
  );
}

function BillToBlock({ order }: BlockProps) {
  const address: AddressFragment = {
    type: AddressType.billing,
    name: order.billingCompanyName,
    street1: order.billingStreet1,
    street2: order.billingStreet2,
    poBox: order.billingPOBox,
    city: order.billingCity,
    state: order.billingState,
    zip: order.billingZip,
  };

  const contact: ContactFragment = {
    firstName: order.billingFirstName,
    lastName: order.billingLastName,
    email: order.billingEmail,
    mobilePhone: order.billingMobilePhone,
    officePhone: order.billingOfficePhone,
    fax: order.billingFax,
  };

  return (
    <>
      <Typography
        component="p"
        sx={{ fontWeight: '700', fontSize: '1.2rem', color: 'primary.main' }}
      >
        Bill To
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={1}>
          <MapIcon sx={{ color: 'info.main' }} />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography component="p" sx={{ fontWeight: '700' }}>
            {order.billingCompanyName}
          </Typography>

          <AddressContent address={address} />
        </Grid>
        <Grid item xs={2} sm={1}>
          <BadgeIcon sx={{ color: 'info.main' }} />
        </Grid>
        <Grid item xs={10} sm={11}>
          <ContactContent contact={contact} />
        </Grid>
      </Grid>
    </>
  );
}

function ShipToBlock({ order, loggedInUser }: BlockProps) {
  const {
    handleCloseModal: handleCloseEditShipToModal,
    handleLaunchModal: handleLaunchEditShipToModal,
    modalOpen: editShipToModalOpen,
  } = useModal();

  const address: AddressFragment = {
    type: AddressType.shipping,
    name: order.shippingCompanyName,
    street1: order.shippingStreet1,
    street2: order.shippingStreet2,
    city: order.shippingCity,
    state: order.shippingState,
    zip: order.shippingZip,
  };

  const contact: ContactFragment = {
    firstName: order.shippingFirstName,
    lastName: order.shippingLastName,
    email: order.shippingEmail,
    mobilePhone: order.shippingMobilePhone,
    officePhone: order.shippingOfficePhone,
    fax: order.billingFax,
  };

  const handleEditShipTo = () => {
    handleLaunchEditShipToModal();
  };

  const onEditShipToAddressSuccess = () => {
    handleCloseEditShipToModal();
  };

  const orderIdToShow = order.id.substring(0, 8);

  const canEditShipTo = loggedInUser.isAdmin && !order.isShipped;

  return (
    <>
      <Stack direction={'row'}>
        <Typography
          component="p"
          sx={{ fontWeight: '700', fontSize: '1.2rem', color: 'primary.main' }}
        >
          Ship To
        </Typography>
        {canEditShipTo && (
          <>
            <Box sx={{ display: 'inline', ml: 2 }}>
              <EditIconButton
                onClick={handleEditShipTo}
                resource="Ship-to Address"
                size="small"
              />
            </Box>
            <FullScreenModal
              title={`Edit Ship To Address for ${orderIdToShow}`}
              open={editShipToModalOpen}
              onClose={handleCloseEditShipToModal}
            >
              <UpdateOrderShipToAddressForm
                order={order}
                onSuccess={onEditShipToAddressSuccess}
              />
            </FullScreenModal>
          </>
        )}
      </Stack>

      {order.type === OrderType.Delivery && (
        <Grid container spacing={1}>
          <Grid item xs={2} sm={1}>
            <MapIcon sx={{ color: 'info.main' }} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography component="p" sx={{ fontWeight: '700' }}>
              {address.name}
            </Typography>
            <AddressContent address={address} />
          </Grid>
          <Grid item xs={2} sm={1}>
            <BadgeIcon sx={{ color: 'info.main' }} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <ContactContent contact={contact} />
          </Grid>
        </Grid>
      )}
      {order.type === OrderType.PickupGreenback && (
        <Grid container spacing={1}>
          <Grid item xs={2} sm={1}>
            <MapIcon sx={{ color: 'info.main' }} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography component="p" sx={{ fontWeight: '700' }}>
              Pickup @ Greenback
            </Typography>
            <Typography component="p">6814 Hwy 411 S.</Typography>
            <Typography component="p">Greenback, TN 37742</Typography>
          </Grid>
        </Grid>
      )}
      {order.type === OrderType.PickupMaryville && (
        <Grid container spacing={1}>
          <Grid item xs={2} sm={1}>
            <MapIcon sx={{ color: 'info.main' }} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography component="p" sx={{ fontWeight: '700' }}>
              Pickup @ Maryville
            </Typography>
            <Typography component="p">736 Alcoa Trail</Typography>
            <Typography component="p">Maryville, TN 37804</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

function ActionsBlock({
  order,
  showNavigateBtn,
  closeModal,
  loggedInUser,
}: BlockProps & { showNavigateBtn: boolean; closeModal?: () => void }) {
  const navigate = useNavigate();

  const {
    handleCloseModal: handleCloseEditOrderNoteModal,
    handleLaunchModal: handleLaunchEditOrderNoteModal,
    modalOpen: editOrderNoteModalOpen,
  } = useModal();

  const {
    confirmDialogIsOpen,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
  } = useConfirmDialog();

  const handlePrintInvoice = async (orderId: string) => {
    createInvoicePdf(order);
  };

  const handlePrintLoadingList = async (orderId: string) => {
    createLoadingListPdf(order);
  };

  const handlePrintOrderConfirmation = async (orderId: string) => {
    createOrderConfirmationPdf(order);
  };

  const handlePrintLoadingListAndInvoice = async (orderId: string) => {
    const orderExtended = await getOrderById(orderId);
    createInvoiceAndLoadingListPdf(orderExtended);
  };

  const onDeleteOrderSuccess = () => {
    console.log('order deleted successfully');
    if (showNavigateBtn) {
      navigate('/admin/orders');
    } else {
      closeModal?.();
    }
  };

  return (
    <Stack direction="row" columnGap={2}>
      {showNavigateBtn && (
        <Button
          onClick={() =>
            loggedInUser.isAdmin
              ? navigate('/admin/orders')
              : navigate('/orders')
          }
          size="small"
          variant="outlined"
        >
          Back to Orders Page
        </Button>
      )}
      <Button
        variant="outlined"
        size="small"
        onClick={() => handlePrintInvoice(order.id)}
      >
        Print Invoice
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={() => handlePrintOrderConfirmation(order.id)}
      >
        Print Order Confirmation
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={() => handlePrintLoadingList(order.id)}
      >
        Print Loading List
      </Button>
      <Button
        variant="outlined"
        size="small"
        sx={{ minWidth: '150px' }}
        onClick={() => handlePrintLoadingListAndInvoice(order.id)}
      >
        Print Both
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{
          minWidth: '150px',
          backgroundColor: 'secondary.main',
          '&:hover': { backgroundColor: 'secondary.dark' },
        }}
        onClick={handleOpenConfirmDialog}
      >
        Delete Order
      </Button>
      {loggedInUser.isAdmin && (
        <DeleteOrderConfirmDialog
          order={order}
          isOpen={confirmDialogIsOpen}
          onClose={handleCloseConfirmDialog}
          onSuccess={onDeleteOrderSuccess}
        />
      )}
    </Stack>
  );
}
