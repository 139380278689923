import { Expose } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';

export class CreateOrUpdateDeliveryRouteDto {
  @IsString()
  @CustomLength(3, 50, 'Name')
  @CustomIsNotEmpty('Name')
  @Expose()
  name: string;

  @IsOptional()
  @IsString()
  @Expose()
  description?: string;
}
