import DeleteIcon from '@mui/icons-material/Delete';
import IconButtonBase from './IconButtonBase';

interface Props {
  onClick: () => any;
  resource?: string;
}

export default function DeleteIconButton({ onClick, resource }: Props) {
  const accessMsg = `Delete ${resource || ''}`.trim();

  return (
    <IconButtonBase edge="end" onClick={onClick} tabIndex={-1}>
      <DeleteIcon aria-label={accessMsg} titleAccess={accessMsg} />
    </IconButtonBase>
  );
}
