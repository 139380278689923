import { Expose } from 'class-transformer';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';
import { AddressType } from '../enums/address-type.enum';
import { States } from '../enums/states.enum';

export class ViewBillingAddressDto extends DefaultUUIDViewDto {
  @Expose() type: AddressType.billing;
  @Expose() name: string;
  @Expose() code: string;
  @Expose() street1?: string;
  @Expose() street2?: string;
  @Expose() poBox?: string;
  @Expose() city: string;
  @Expose() state: States;
  @Expose() zip: string;
  @Expose() customerId: string;
}
