import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CreateProductDto } from '../../../../lib/api/products/dto/create-product.dto';
import FromSubmitButton from '../../../ui/forms/FormSubmitButton';
import ProductOptionsArray from '../ProductOptionsArray';

export default function AddProductFormOptionsPartial() {
  const {
    formState: { isSubmitting, isValid, isSubmitted },
    setValue,
    watch,
  } = useFormContext<CreateProductDto>();

  const options = watch().options;

  useEffect(() => {
    if (options.length === 0)
      setValue('options', [{ name: '', values: [], children: [] }]);
  }, [options, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Configure Options for Product Variants
        </Typography>
      </Grid>
      <ProductOptionsArray />
      <Grid item xs={12}>
        <FromSubmitButton
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
          isValid={isValid}
          text="Create Product"
        />
      </Grid>
    </Grid>
  );
}
