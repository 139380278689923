import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppContext } from '../../../contexts/AppContext';
import { CustomerQueryKeys } from '../../../lib/api/customers/customers.service';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { plainToInstance } from 'class-transformer';
import { Grid } from '@mui/material';
import FromSubmitButton from '../../ui/forms/FormSubmitButton';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import { ViewShippingAddressExtendedDto } from '../../../lib/api/addresses/dto/view-shipping-address-extended.dto';
import { UpdateShippingAddressDto } from '../../../lib/api/addresses/dto/update-shipping-address.dto';
import { updateShippingAddress } from '../../../lib/api/addresses/addresses.service';
import CustomForm, { onSuccessCallback } from '../../ui/forms/CustomForm';
import ShippingAddressPartial from './ShippingAddressPartial';
import { VALIDATION_ERR_DELAY } from '../../../config/AppConstants';

interface Props {
  address: ViewShippingAddressExtendedDto;
  onSuccess?: onSuccessCallback;
}

export default function UpdateShippingAddressForm({
  address,
  onSuccess,
}: Props) {
  const updateShippingAddressDto = plainToInstance(
    UpdateShippingAddressDto,
    address,
    {
      excludeExtraneousValues: true,
      exposeUnsetFields: false,
      exposeDefaultValues: false,
    },
  );

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (updateShippingAddressDto: UpdateShippingAddressDto) =>
      updateShippingAddress(address.id, updateShippingAddressDto),
  );

  const form = useForm<UpdateShippingAddressDto>({
    resolver: classValidatorResolver(UpdateShippingAddressDto),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: { ...updateShippingAddressDto },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    setError,
  } = form;

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateShippingAddressDto>();

  const onSubmit: SubmitHandler<UpdateShippingAddressDto> = async (
    formData: UpdateShippingAddressDto,
  ) => {
    clearFormSubmitError();

    const updateShippingAddressDto = plainToInstance(
      UpdateShippingAddressDto,
      formData,
      {
        excludeExtraneousValues: true,
        exposeDefaultValues: false,
        exposeUnsetFields: false,
      },
    );

    try {
      const updatedAddress = await updateMutation.mutateAsync(
        updateShippingAddressDto,
      );
      queryClient.invalidateQueries([
        CustomerQueryKeys.findCustomerById,
        { id: updatedAddress.customerId },
      ]);

      handleShowMessage(
        `Updated Shipping Address @ '${updatedAddress.street1}'`,
      );
      onSuccess?.(updatedAddress);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <FormProvider {...form}>
      <CustomForm
        serverErrorMessage={serverErrorMessage}
        handleSubmit={handleSubmit(onSubmit)}
      >
        <ShippingAddressPartial />
        <Grid item xs={12}>
          <FromSubmitButton
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isValid={isValid}
            text="Update Shipping Address"
          />
        </Grid>
      </CustomForm>
    </FormProvider>
  );
}
