import { Button, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ViewContactDto } from '../../../lib/api/contacts/dto/view-contact.dto';
import { ViewCustomerExtendedDto } from '../../../lib/api/customers/dto/view-customer-extended.dto';
import { CreateOrderDto } from '../../../lib/api/orders/dto/create-order.dto';
import AddOrUpdateContactForm from '../../contacts/AddOrUpdateContactForm';
import FullScreenModal from '../../ui/modal/FullScreenModal';
import useModal from '../../ui/modal/useModal';
import ContactSelectionList from './ContactSelectionList';

interface Props {
  customer: ViewCustomerExtendedDto;
  onClick?: (type: 'billing', contactId: string) => void;
}

export default function BillingAddressContactPartial({
  customer,
  onClick,
}: Props) {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext<CreateOrderDto>();

  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();

  const onCreateContactSuccess = () => {
    handleCloseModal();
  };

  const handleAddContact = () => {
    handleLaunchModal();
  };

  const watchedBillingAddressId = watch('billingAddressId');

  if (!customer) return;

  const billingAddress = customer.addresses.find(
    (a) => a.id === watchedBillingAddressId,
  );

  if (!billingAddress) return;

  const handleContactClick = (contact: ViewContactDto) => {
    onClick('billing', contact.id);
  };

  const numContacts = billingAddress?.contacts?.length || 0;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h5">
            Who is the billing contact on this order?
          </Typography>
        </Grid>

        {numContacts > 0 && (
          <>
            <ContactSelectionList
              contacts={billingAddress.contacts}
              onClick={handleContactClick}
            />
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button onClick={handleAddContact}>Create New Contact</Button>
            </Grid>
          </>
        )}
        {numContacts === 0 && (
          <>
            <Grid item xs={12} columnGap={2} sx={{ mb: 2 }}>
              <span>
                There are no contacts on file for this address{' '}
                <Typography
                  component="a"
                  sx={{
                    textDecoration: 'underline',
                    color: 'primary.main',
                    cursor: 'pointer',
                    ':hover': { color: 'primary.dark' },
                  }}
                  onClick={handleAddContact}
                >
                  Create One Now
                </Typography>
              </span>
            </Grid>
          </>
        )}
      </Grid>
      <FullScreenModal
        title={`Create Billing Contact for ${billingAddress.name}`}
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AddOrUpdateContactForm
          addressId={billingAddress.id}
          onSuccess={onCreateContactSuccess}
        />
      </FullScreenModal>
    </>
  );
}
