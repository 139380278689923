import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import { useCartContext } from '../../contexts/CartContext';
import AdminCheckoutForm from '../checkout-form/AdminCheckoutForm';
import FullScreenModal from '../ui/modal/FullScreenModal';
import useModal from '../ui/modal/useModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  checkoutDisabled: boolean;
}

export default function CartActions({ checkoutDisabled }: Props) {
  const { cart, storeStatus, isExpired } = useCartContext();
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  if (!cart) return null;
  if (!cart.productTotalBeforeDiscounts) return null;

  if (isExpired) return null;

  const onCreateOrderSuccess = () => {
    handleCloseModal();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'rack-utilization-popover' : undefined;

  const shoppingForCurrentWeek =
    cart.orderWeek === storeStatus.currentWeek.orderWeek;

  return (
    <Box sx={{ mt: 3 }}>
      <Button
        variant="contained"
        onClick={
          shoppingForCurrentWeek
            ? handleLaunchModal
            : (e) => setAnchorEl(e.currentTarget)
        }
        disabled={checkoutDisabled}
      >
        Checkout
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -0,
          horizontal: -10,
        }}
      >
        <Typography
          sx={{
            p: 2,
          }}
          component="div"
        >
          <h3>Order Not for Current Week</h3>
          <p>
            Just a heads up! You are placing an order for a week other than the
            current week.
          </p>
          <p>
            If that was your intention, keep going. Otherwise go back to store
            and update your order week!
          </p>
          <p>
            <Typography
              sx={{ fontWeight: 'bold', display: 'inline' }}
              component="span"
            >
              Current Week:{' '}
            </Typography>
            {storeStatus.currentWeek.orderWeek}
          </p>
          <p>
            <Typography
              sx={{ fontWeight: 'bold', display: 'inline' }}
              component="span"
            >
              Ordering for Week:{' '}
            </Typography>
            {cart.orderWeek}
          </p>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              onClick={() => navigate('/admin/store')}
            >
              Back to Store
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLaunchModal}
            >
              Checkout
            </Button>
          </Stack>
        </Typography>
      </Popover>
      <FullScreenModal
        title="Create Order"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <AdminCheckoutForm onSuccess={onCreateOrderSuccess} />
      </FullScreenModal>
    </Box>
  );
}
