import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { plainToInstance } from 'class-transformer';
import { useAppContext } from '../../../contexts/AppContext';
import { ProductQueryKeys } from '../../../lib/api/products/products.service';
import useFormSubmitErrorAlert from '../../ui/forms/useFormSubmitErrorAlert';
import MultiStepFormPage from '../../ui/forms/MultiStepFormPage';
import UpdateVariantFormDetailsPartial from './UpdateVariantFormDetailsPartial';
import MultiStepForm from '../../ui/forms/MultiStepForm';
import { StepperData } from '../../ui/forms/CustomStepper';
import useMultiStepForm from '../../ui/forms/useMultiStepForm';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import UpdateVariantFormStoreAvailabilityPartial from './UpdateVariantFormStoreAvailabilityPartial';
import { onSuccessCallback } from '../../ui/forms/CustomForm';
import { UpdateVariantDto } from '../../../lib/api/variants/dto/update-variant.dto';
import {
  getVariantById,
  updateVariant,
  VariantQueryKeys,
} from '../../../lib/api/variants/variants.service';
import { ViewProductExtendedDto } from '../../../lib/api/products/dto/view-product-extended.dto';
import { DtoStringKeysAsPath } from '../../../lib/util/type-utils';

interface Props {
  product: ViewProductExtendedDto;
  variantId: string;
  onSuccess?: onSuccessCallback;
}

export default function UpdateVariantForm({
  product,
  variantId,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const { data: variant } = useQuery(
    [VariantQueryKeys.findVariantById, { id: variantId }],
    () => getVariantById(variantId),
  );

  useEffect(() => {
    if (variant) {
      for (const key in form.getValues()) {
        form.setValue(
          key as DtoStringKeysAsPath<UpdateVariantDto>,
          variant[key as DtoStringKeysAsPath<UpdateVariantDto>],
        );
      }
    }
  }, [variant]);

  const queryClient = useQueryClient();

  const updateMutation = useMutation((upateVariantDto: UpdateVariantDto) =>
    updateVariant(variant.id, upateVariantDto),
  );

  const [steps, setSteps] = useState<StepperData<UpdateVariantDto>[]>([
    {
      text: 'Product Details',
      isActive: true,
      fieldsInStep: ['name', 'description', 'itemNo', 'sku', 'isProvenWinner'],
      focusField: 'name',
      fieldsToValidateOnNext: [
        'name',
        'description',
        'itemNo',
        'sku',
        'isProvenWinner',
      ],
    },
    {
      text: 'Store Availability',
      fieldsInStep: [
        'isSellable',
        'sellToPopes',
        'sellToPremium',
        'sellToStandard',
        'orderLimit',
      ],
      focusField: 'isSellable',
      fieldsToValidateOnNext: [
        'isSellable',
        'sellToPopes',
        'sellToPremium',
        'sellToStandard',
        'orderLimit',
      ],
    },
  ]);

  const form = useMultiStepForm<UpdateVariantDto>({
    resolver: classValidatorResolver(UpdateVariantDto, {
      forbidUnknownValues: true,
    }),
    defaultValues: {
      name: variant?.name,
      description: variant?.description,
      itemNo: variant?.itemNo,
      sku: variant?.sku,
      isProvenWinner: variant?.isProvenWinner || false,
      isSellable: variant?.isSellable || true,
      sellToPopes: variant?.sellToPopes || true,
      sellToPremium: variant?.sellToPremium || true,
      sellToStandard: variant?.sellToStandard || true,
      orderLimit: variant?.orderLimit,
    },
    steps,
    setSteps,
  });

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<UpdateVariantDto>();

  const onSubmit: SubmitHandler<UpdateVariantDto> = async (
    formData: UpdateVariantDto,
  ) => {
    clearFormSubmitError();

    const updateDto = plainToInstance(UpdateVariantDto, formData, {
      excludeExtraneousValues: true,
      exposeDefaultValues: false,
      exposeUnsetFields: false,
    });

    try {
      const updatedVariant = await updateMutation.mutateAsync(updateDto);
      queryClient.invalidateQueries([
        ProductQueryKeys.findProductVariants,
        { id: updatedVariant.productId },
      ]);
      handleShowMessage(`Updated Variant '${updateDto.name}'`);
      onSuccess?.(updatedVariant);
    } catch (err: any) {
      processFormSubmitError(err, form.setError);
    }
  };

  if (!variant) return;

  return (
    <FormProvider {...form}>
      <MultiStepForm
        handleSubmit={form.handleSubmit(onSubmit)}
        serverErrorMessage={serverErrorMessage}
        steps={steps}
      >
        <MultiStepFormPage
          step={steps[0]}
          onNext={form.handleNextClick}
          errors={form.formState.errors}
        >
          <UpdateVariantFormDetailsPartial
            product={product}
            variant={variant}
          />
        </MultiStepFormPage>

        <MultiStepFormPage
          step={steps[1]}
          onBack={form.handleBackClick}
          errors={form.formState.errors}
        >
          <UpdateVariantFormStoreAvailabilityPartial
            product={product}
            variant={variant}
          />
        </MultiStepFormPage>
      </MultiStepForm>
    </FormProvider>
  );
}
