import { Expose, Type } from 'class-transformer';
import {
  Equals,
  IsEnum,
  IsOptional,
  IsString,
  NotContains,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { OnlyOnePopulated } from '../../../common/custom-class-validators/OnlyOnePopulated';
import { IsValidStreet2 } from '../custom-class-validators/IsValidStreet2';
import { AddressType } from '../enums/address-type.enum';
import { States } from '../enums/states.enum';
import { CreateOrUpdateContactDto } from '../../contacts/dto/create-or-update-contact.dto';
import CustomMaxLength from '../../../common/custom-class-validators/CustomMaxLength';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';

export class CreateBillingAddressDto {
  @Expose()
  @Equals(AddressType.billing)
  type: AddressType.billing;

  @Expose()
  @IsString()
  @CustomLength(3, 100, 'Company Name')
  name: string;

  @Expose()
  @IsString()
  @CustomMaxLength(25, 'Code')
  @CustomIsNotEmpty('Code')
  code: string;

  @Expose()
  @ValidateIf((o) => !o.poBox)
  @IsString()
  @CustomLength(3, 35, 'Street')
  @OnlyOnePopulated('poBox')
  street1?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @CustomLength(3, 35, 'Street')
  @IsValidStreet2()
  street2?: string;

  @Expose()
  @ValidateIf((o) => !o.street1)
  @IsString()
  @CustomMaxLength(10, 'PO Box')
  @NotContains('po', { message: 'Enter the PO Box Number only.' })
  @NotContains('PO', { message: 'Enter the PO Box Number only.' })
  @OnlyOnePopulated('street1')
  poBox?: string;

  @Expose()
  @IsString()
  @CustomLength(3, 30, 'City')
  @CustomIsNotEmpty('City')
  city: string;

  @Expose()
  @IsEnum(States, {
    message: 'State must be populated from the list provided.',
  })
  @CustomIsNotEmpty('State')
  state: States;

  @Expose()
  @IsString()
  @CustomLength(5, 11, 'Zip')
  @CustomIsNotEmpty('Zip')
  zip: string;

  @Expose()
  @Type(() => CreateOrUpdateContactDto)
  @IsOptional()
  @ValidateNested()
  contact?: CreateOrUpdateContactDto;
}
