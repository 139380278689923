import { Expose, Type } from 'class-transformer';
import {
  Equals,
  IsEnum,
  IsInt,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { IsValidStreet2 } from '../custom-class-validators/IsValidStreet2';
import { AddressType } from '../enums/address-type.enum';
import { States } from '../enums/states.enum';
import { CreateOrUpdateContactDto } from '../../contacts/dto/create-or-update-contact.dto';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';

export class CreateShippingAddressDto {
  @Expose()
  @Equals(AddressType.shipping)
  type: AddressType.shipping;

  @Expose()
  @IsString()
  @CustomLength(3, 100, 'Company Name')
  name: string;

  @Expose()
  @IsString()
  @CustomLength(3, 35, 'Street')
  @CustomIsNotEmpty('Street')
  street1: string;

  @Expose()
  @IsOptional()
  @IsString()
  @CustomLength(3, 35, 'Street')
  @IsValidStreet2()
  street2?: string;

  @Expose()
  @IsString()
  @CustomLength(3, 30, 'City')
  @CustomIsNotEmpty('City')
  city: string;

  @Expose()
  @IsEnum(States, {
    message: 'State must be populated from the list provided.',
  })
  @CustomIsNotEmpty('State')
  state: States;

  @Expose()
  @IsString()
  @CustomLength(5, 11, 'Zip')
  @CustomIsNotEmpty('Zip')
  zip: string;

  @Expose()
  @IsOptional()
  @IsInt()
  routeId?: number;

  @Expose()
  @Type(() => CreateOrUpdateContactDto)
  @IsOptional()
  @ValidateNested()
  contact?: CreateOrUpdateContactDto;
}
