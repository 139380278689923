import { FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import RequiredInputLabel from './RequiredInputLabel';
import { cleanSkuString } from './../../../lib/util/misc-utils';

type Props = TextFieldProps & {
  name: string;
  label: string;
  control: Control<any, any>;
  required?: boolean;
  charCountLimit?: number;
};

export default function RhfSkuField({
  name,
  label,
  control,
  required,
  autoComplete,
  variant,
  margin,
  charCountLimit,
  ...rest
}: Props) {
  const handleOnChange = (value: any) => {
    value = cleanSkuString(value); //Removes chars and uppercases
    value = emptyToNull(value);
    if (!value) return value;
    return value;
  };

  const handleOnBlur = (value: any, onBlur: () => void) => {
    onBlur();
    return value ? value.trim() : null;
  };

  const emptyToNull = (value: string): string | null => {
    if (value === null || value === undefined || value === '') return null;
    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, ref, name, value },
        fieldState: { error },
      }) => (
        <>
          <TextField
            inputRef={ref}
            name={name}
            helperText={error && error.message}
            error={error ? true : false}
            label={required ? <RequiredInputLabel text={label} /> : label}
            autoComplete={autoComplete || 'off'}
            autoSave="off"
            variant={variant || 'outlined'}
            margin={margin || 'normal'}
            inputProps={{
              'data-lpignore': true,
            }}
            onChange={(e: any) => onChange(handleOnChange(e.target.value))}
            onBlur={(e: any) => onChange(handleOnBlur(e.target.value, onBlur))}
            value={value || ''}
            fullWidth
            {...rest}
          />
          {charCountLimit && (
            <FormHelperText>{`${
              value?.length || 0
            } / ${charCountLimit}`}</FormHelperText>
          )}
        </>
      )}
    />
  );
}
