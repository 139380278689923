import { Box } from '@mui/material';
import { useState } from 'react';
import { ViewContactDto } from '../../lib/api/contacts/dto/view-contact.dto';
import DeleteIconButton from '../ui/buttons/DeleteIconButton';
import EditIconButton from '../ui/buttons/EditIconButton';
import ContactContent from './ContactContent';

interface Props {
  contact: ViewContactDto;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export default function ContactListItem({ contact, onDelete, onEdit }: Props) {
  const [showDeleteContact, setShowDeleteContact] = useState(false);

  return (
    <Box
      display="flex"
      columnGap={2}
      onMouseEnter={() => setShowDeleteContact(true)}
      onMouseLeave={() => setShowDeleteContact(false)}
    >
      <Box flexGrow={1}>
        <ContactContent contact={contact} />
      </Box>
      <Box hidden={!showDeleteContact}>
        <EditIconButton
          resource="Contact"
          onClick={() => onEdit?.(contact.id)}
        />
        <DeleteIconButton
          resource="Contact"
          onClick={() => onDelete?.(contact.id)}
        />
      </Box>
    </Box>
  );
}
