import { TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useAppContext } from '../../../contexts/AppContext';
import { getErrorMessage } from '../../../lib/util/misc-utils';
import {
  InventoryQueryKeys,
  removeInventoryItemPhysicalCount,
  updateInventoryItem,
} from '../../../lib/api/inventory/inventory.service';
import { UpdateInventoryItemPhysicalCountDto } from '../../../lib/api/inventory/dto/udpate-inventory-item-physical-count.dto';
import { InventoryWithCalculatedFields } from '../table/InventoryAvailabilityTable';

interface Props {
  name: string;
  label: string;
  decimals?: number;
  item: InventoryWithCalculatedFields;
}

export default function PhysicalCountInput({
  name,
  label,
  decimals,
  item,
}: Props) {
  if (!decimals) decimals = 2;

  const [error, setError] = useState(null);

  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const updatePhysicalCountMutation = useMutation(
    (updateInventoryItemDto: UpdateInventoryItemPhysicalCountDto) =>
      updateInventoryItem(updateInventoryItemDto),
  );

  const removePhysicalCountMutation = useMutation(
    ({ id, type }: { id: string; type: 'product' | 'variant' }) =>
      removeInventoryItemPhysicalCount(id, type),
  );

  const updatePhysicalCount = async (value: string) => {
    setError(null);

    //No Change - don't submit to server
    if (value === item.physicalCount?.toFixed(2)) return;

    //If we have a blank value and we have not completed the physical
    //count for the week, don't bother clearing it out.
    if (value === '' && !item.physicalCountForWeekComplete) return;

    try {
      if (value === '' || value === null) {
        //Blank value -- Remove the Physical Count

        let type: 'product' | 'variant' = item.variantId
          ? 'variant'
          : 'product';
        let id = item.variantId || item.productId;

        const res = await removePhysicalCountMutation.mutateAsync({ id, type });

        if (item.variantId) {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findVariantsInventoryByProductId,
            { id: item.productId },
          ]);
        } else {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findInventoryForSimpleProductsByCategoryId,
            { id: res.categoryId },
          ]);
        }
      } else {
        //Upate based on value
        let updateObj: UpdateInventoryItemPhysicalCountDto;

        if (item.variantId) {
          updateObj = {
            variantId: item.variantId,
            physicalCount: value,
          };
        } else {
          updateObj = {
            productId: item.productId,
            physicalCount: value,
          };
        }
        const res = await updatePhysicalCountMutation.mutateAsync(updateObj);

        // onUpdatePhysicalCount(res);

        if (item.variantId) {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findVariantsInventoryByProductId,
            { id: item.productId },
          ]);
        } else {
          queryClient.invalidateQueries([
            InventoryQueryKeys.findInventoryForSimpleProductsByCategoryId,
            { id: res.categoryId },
          ]);
        }
      }
    } catch (err: any) {
      setError(getErrorMessage(err));
    }
  };

  return (
    <NumericFormat
      name={name}
      allowNegative={false}
      decimalScale={decimals}
      thousandSeparator=","
      customInput={TextField}
      helperText={error || null}
      error={error ? true : false}
      label={label}
      variant="outlined"
      size="small"
      onBlur={(e) => updatePhysicalCount(e.currentTarget.value)}
      value={
        item.physicalCountForWeekComplete ? item.physicalCount.toFixed(2) : ''
      }
      defaultValue=""
      margin="normal"
      fullWidth
    />
  );
}
