import { Control } from 'react-hook-form';
import RhfDecimalInput from './RhfDecimalInput';

interface Props {
  name: string;
  label: string;
  control: Control<any, any>;
  decimals?: number;
  required?: boolean;
}

export default function RhfCurrencyInput({ decimals, ...rest }: Props) {
  if (!decimals) decimals = 2;

  return <RhfDecimalInput {...rest} decimals={decimals} showMoney />;
}
