import axios, { AxiosError, AxiosResponse } from 'axios';
import { FormattedValidationError } from '../../components/ui/forms/useFormSubmitErrorAlert';

//The res for any error the API Returns that is not a 400
export interface GeneralServerErrorRes {
  statusCode: number;
  message: string;
  error: any;
}

//The res for 400s;
export interface BadRequestErrorRes {
  statusCode: 400;
  message: string;
  errors: FormattedValidationError;
}

export interface ClientError {
  message: string;
}

export type WebClientError =
  | GeneralServerErrorRes
  | BadRequestErrorRes
  | ClientError;

export const apiClientFactory = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  instance.interceptors.response.use(resInterceptor, errInterceptor);
  return instance;
};

export const generalClientFactory = () => {
  const instance = axios.create();
  instance.interceptors.response.use(resInterceptor, errInterceptor);
  return instance;
};

const resInterceptor = (res: AxiosResponse) => {
  return res;
};

const errInterceptor = (err: AxiosError) => {
  if (err.response) {
    throw new Error(JSON.stringify(err.response.data));
  } else if (err.request) {
    const errToThrow = {
      message: err.message
        ? err.message
        : 'Request was made, but response was not received.',
    };
    throw new Error(JSON.stringify(errToThrow));
  } else {
    const errToThrow = {
      message: `Request could not be setup made.`,
    };
    throw new Error(JSON.stringify(errToThrow));
  }
};
