export enum SageUom {
  'Not Set' = 'Not Set',
  'Count' = 'Count',
  'Flat of 6' = 'Flat of 6',
  'Flat of 8' = 'Flat of 8',
  'Flat of 10' = 'Flat of 10',
  'Flat of 12' = 'Flat of 12',
  'Flat of 18' = 'Flat of 18',
  'Flat of 20' = 'Flat of 20',
  'Flat of 32' = 'Flat of 32',
}
