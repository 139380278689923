import { plainToInstance } from 'class-transformer';
import { apiClientFactory } from '../../common/clients';
import { getHeaders } from '../../util/http-utils';
import { UpdateVariantDto } from './dto/update-variant.dto';
import { ViewVariantExtendedDto } from './dto/view-variant-extended.dto';
import { ViewVariantDto } from './dto/view-variant.dto';
import { UpdateVariantAvailabilityFlagsDto } from './dto/update-variant-availability-flags.dto';

const apiClient = apiClientFactory();

/****************************
Product Variants
****************************/

const resource = 'variants';

export enum VariantQueryKeys {
  'findVariantById' = 'FindVariantById',
}

export async function getVariantById(
  variantId: string,
): Promise<ViewVariantExtendedDto> {
  const { data } = await apiClient.get(`v1/${resource}/${variantId}`, {
    headers: getHeaders(),
  });
  return plainToInstance(ViewVariantExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}

export async function updateVariant(
  id: string,
  updateVariantDto: UpdateVariantDto | UpdateVariantAvailabilityFlagsDto,
): Promise<ViewVariantExtendedDto> {
  const { data } = await apiClient.patch(
    `v1/${resource}/${id}`,
    updateVariantDto,
    {
      headers: getHeaders(),
    },
  );
  return plainToInstance(ViewVariantExtendedDto, data, {
    excludeExtraneousValues: true,
  });
}
