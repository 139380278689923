import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationOptions,
  registerDecorator,
} from 'class-validator';

@ValidatorConstraint({ async: true })
class IsValidPasswordConstraint implements ValidatorConstraintInterface {
  async validate(value: any) {
    const passwordRegEx = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    );

    return passwordRegEx.test(value);
  }

  defaultMessage() {
    return 'Password must be at least 8 characters long, contain at least one number, one lower case letter, one uppercase letter and one special character. ';
  }
}

export function IsValidPassword(validationOptions?: ValidationOptions) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidPasswordConstraint,
    });
  };
}
