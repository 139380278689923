import { Expose, Type } from 'class-transformer';
import { ViewAddress } from '../../addresses/dto/view-address';
import { ViewBillingAddressExtendedDto } from '../../addresses/dto/view-billing-address-extended.dto';
import { ViewShippingAddressExtendedDto } from '../../addresses/dto/view-shipping-address-extended.dto';
import { AddressType } from '../../addresses/enums/address-type.enum';
import { ViewUserDto } from '../../users/dto/view-user.dto';
import { ViewCustomerDto } from './view-customer.dto';

export class ViewCustomerExtendedDto extends ViewCustomerDto {
  @Type(() => ViewUserDto)
  @Expose()
  users: ViewUserDto[];

  @Type(() => ViewAddress, {
    discriminator: {
      property: 'type',
      subTypes: [
        {
          value: ViewBillingAddressExtendedDto,
          name: AddressType.billing,
        },
        {
          value: ViewShippingAddressExtendedDto,
          name: AddressType.shipping,
        },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  addresses: Array<
    ViewBillingAddressExtendedDto | ViewShippingAddressExtendedDto
  >;
}
