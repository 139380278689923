import { useQuery } from '@tanstack/react-query';
import ProductDetailsTab from './ProductDetailsTab';
import {
  ProductQueryKeys,
  getProductById,
} from '../../../lib/api/products/products.service';
import TabGroup from '../../ui/drawer/DetailsDrawerContent';
import ProductOptionsTab from './ProductOptionsTab';
import useTabs from '../../ui/tabs/useTabs';
import ProductVariantsTab from './ProductVariantsTab';

interface DrawerContentProps {
  productId: string;
}

export default function ProductDrawerContent({
  productId,
}: DrawerContentProps) {
  const {
    isError,
    data: product,
    error,
    isFetching,
  } = useQuery([ProductQueryKeys.findProductById, { id: productId }], () =>
    getProductById(productId),
  );

  const { tabValue, handleChangeTab } = useTabs();

  if (!product) return null;

  return (
    <TabGroup tabValue={tabValue} onTabChange={handleChangeTab}>
      <ProductDetailsTab
        label="Details"
        tabValue={tabValue}
        index={0}
        product={product}
      />
      {product.hasVariants && (
        <ProductOptionsTab
          label="Options"
          tabValue={tabValue}
          index={1}
          product={product}
        />
      )}
      {product.hasVariants && (
        <ProductVariantsTab
          label="Variants"
          tabValue={tabValue}
          index={2}
          product={product}
        />
      )}
    </TabGroup>
  );
}
