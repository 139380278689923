import { Expose } from 'class-transformer';
import {
  IsBoolean,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsPositive,
  Length,
  MaxLength,
  Min,
  MinLength,
} from 'class-validator';
import { IsNumericString } from '../../../common/custom-class-validators/IsNumericString';
import { NumericStringMin } from '../../../common/custom-class-validators/NumericStringMin';
import { ProductLocation } from '../enums/product-locations.enum';
import { SageUom } from '../enums/sage-uoms.enum';

export class UpdateProductDto {
  @MinLength(3, { message: 'Name must have at least 3 characters' })
  @MaxLength(100, { message: 'Name must not have more than 100 characters' })
  @IsNotEmpty({ message: 'Name must be provided' })
  @Expose()
  name: string;

  @IsOptional()
  @MaxLength(255, {
    message: 'Description must be no more than 255 characters',
  })
  @Expose()
  description?: string;

  @IsOptional()
  @Length(12, 12, { message: 'UPC must be 12 characters' })
  @Expose()
  upc?: string;

  @IsOptional()
  @MinLength(3, { message: 'Item # must have at least 3 characters' })
  @MaxLength(20, { message: 'Item # must not have more than 20 characters' })
  @Expose()
  itemNo?: string;

  @MinLength(3, { message: 'SKU must have at least 3 characters' })
  @MaxLength(50, { message: 'SKU must not have more than 50 characters' })
  @IsNotEmpty({ message: 'SKU must be provided' })
  @Expose()
  sku: string;

  @IsInt({ message: `Qty/Shelf must be a whole number` })
  @Min(1, { message: `Qty/Shelf must be 1 or more` })
  @IsNotEmpty({ message: `Qty/Shelf must be provided` })
  @Expose()
  qtyPerShelf: number;

  @IsInt({ message: `Shelves/Rack must be a whole number` })
  @Min(1, { message: `Shelves/Rack must be 1 or more` })
  @IsNotEmpty({ message: `Shelves/Rack must be provided` })
  @Expose()
  shelvesPerRack: number;

  @IsNumericString(0, 2)
  @NumericStringMin(0, { message: `Price must be positive` })
  @IsNotEmpty({ message: `Price must be provided` })
  @Expose()
  price: string;

  @IsEnum(ProductLocation)
  @Expose()
  location: ProductLocation;

  @IsOptional()
  @IsInt({ message: 'Order Limit must be a whole number when provided' })
  @IsPositive({ message: 'Order Limit must be positive when provided' })
  @Expose()
  orderLimit?: string;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isProvenWinner: boolean;

  @IsBoolean()
  @Expose()
  isSellable: boolean;

  @IsBoolean()
  @Expose()
  sellToPopes: boolean;

  @IsBoolean()
  @Expose()
  sellToPremium: boolean;

  @IsBoolean()
  @Expose()
  sellToStandard: boolean;

  @IsNotEmpty({ message: 'You must select a category' })
  @Expose()
  categoryId: number;

  @IsEnum(SageUom)
  @Expose()
  sageUom: SageUom;
}
