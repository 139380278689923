import { Expose, Type } from 'class-transformer';
import { IsString, ValidateNested } from 'class-validator';
import CustomIsNotEmpty from '../../../common/custom-class-validators/CustomIsNotEmpty';
import CustomLength from '../../../common/custom-class-validators/CustomLength';
import { CreateChildProductOptionValueForNewProductDto } from '../../product-option-values/dto/create-child-product-option-value-for-new-product.dto';

export class CreateChildProductOptionForNewProductDto {
  @Expose()
  @IsString()
  @CustomLength(3, 25, 'Option Name')
  @CustomIsNotEmpty('Option Name')
  name: string;

  @Expose()
  @ValidateNested()
  @Type(() => CreateChildProductOptionValueForNewProductDto)
  values: CreateChildProductOptionValueForNewProductDto[];
}
