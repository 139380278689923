import { Expose, Type } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { ViewVariantOptionDto } from './view-variant-option.dto';

export class ViewSellableVariantDto {
  @Expose() id: string;
  @Expose() name: string;
  @Expose() description?: string;
  @Expose() sku: string;
  @Expose() itemNo?: string;
  @Expose() orderLimit?: number;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  availableQty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  qty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  reservedQty: Decimal;

  @Expose() isSellable: boolean;
  @Expose() sellToPopes: boolean;
  @Expose() sellToPremium: boolean;
  @Expose() sellToStandard: boolean;
  @Expose() isProvenWinner: boolean;
  @Expose() sortOrder: number;

  @Expose()
  defaultImageFileUrl?: string;

  @Expose()
  defaultImageFileNameKey?: string;

  @Expose()
  @Type(() => ViewVariantOptionDto)
  options: ViewVariantOptionDto[];
}
