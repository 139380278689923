import { Expose } from 'class-transformer';
import Decimal from 'decimal.js';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import { DefaultUUIDViewDto } from '../../../common/dto/default-uuid-view.dto';
import { ViewVariantOptionDto } from './view-variant-option.dto';

export class ViewVariantDto extends DefaultUUIDViewDto {
  @Expose() productId: string;
  @Expose() name: string;
  @Expose() description?: string;
  @Expose() sku: string;
  @Expose() itemNo?: string;
  @Expose() orderLimit?: number;
  @Expose() @TransformStringToDecimal({ toClassOnly: true }) qty: Decimal;
  @Expose() isSellable: boolean;
  @Expose() sellToPopes: boolean;
  @Expose() sellToPremium: boolean;
  @Expose() sellToStandard: boolean;
  @Expose() isProvenWinner: boolean;
  @Expose() sortOrder: number;
  // @Expose() options: ViewVariantOptionDto[];
  @Expose() defaultImageFileUrl?: string;
}
