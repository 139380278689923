import {
  Alert,
  Box,
  Button,
  Paper,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useCartContext } from '../../contexts/CartContext';
import { DateTime } from 'luxon';
import ExpiredAlert from '../cart/ExpiredAlert';
import useAuth from '../../lib/api/auth/useAuth';
import { usd } from '../../lib/util/misc-utils';
import OrderMinimumMsg from '../cart/OrderMinimumMsg';
import ViewCartExtendedDto from '../../lib/api/carts/dto/view-cart-extended.dto';
import { ViewStoreStatusDto } from '../../lib/api/store/dto/view-store-status.dto';
import CartSummaryItemsList from './CartSummaryItemsList';
import { useState } from 'react';
interface Props {
  onCheckout: () => void;
}

export default function CartSummary({ onCheckout }: Props) {
  const {
    cart,
    isExpired,
    isPreviousOrderWeek,
    isCurrentOrderWeek,
    isFutureOrderWeek,
    storeStatus,
  } = useCartContext();
  const { loggedInUser } = useAuth();

  if (!cart) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        mr: 1,
        position: 'sticky',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        maxHeight: '75%',
        marginBottom: '',
      }}
    >
      <CartSummaryHeader
        isAdmin={loggedInUser.isAdmin}
        isPreviousOrderWeek={isPreviousOrderWeek}
        isCurrentOrderWeek={isCurrentOrderWeek}
        isFutureOrderWeek={isFutureOrderWeek}
      />

      <CartSummaryItemsList items={cart.items} />

      {cart.items.length > 0 && (
        <CartSummaryFooter
          isAdmin={loggedInUser.isAdmin}
          isRetail={loggedInUser.isRetail}
          isExpired={isExpired}
          isPreviousOrderWeek={isPreviousOrderWeek}
          cart={cart}
          storeStatus={storeStatus}
          onCheckout={onCheckout}
        />
      )}
    </Paper>
  );
}

interface CartSummaryHeaderProps {
  isAdmin: boolean;
  isPreviousOrderWeek: boolean;
  isCurrentOrderWeek: boolean;
  isFutureOrderWeek: boolean;
}
function CartSummaryHeader({
  isAdmin,
  isPreviousOrderWeek,
  isCurrentOrderWeek,
  isFutureOrderWeek,
}: CartSummaryHeaderProps) {
  const getSummaryText = () => {
    if (isAdmin) {
      if (isPreviousOrderWeek) return `Cart Summary (EXPIRED WEEK)`;
      if (isCurrentOrderWeek) return `Cart Summary (Current Week)`;
      if (isFutureOrderWeek) return `Cart Summary (FUTURE WEEK)`;
    } else {
      return 'Cart Summary';
    }
  };

  return (
    <Box
      sx={{
        p: 1,
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.paper',
        zIndex: 100,
        height: '60px',
        borderBottom: '3px solid',
        borderBottomColor: 'primary.main',
      }}
    >
      <Typography variant="h6" component="div">
        {getSummaryText()}
      </Typography>
    </Box>
  );
}

interface CartSummaryFooterProps {
  isAdmin: boolean;
  isRetail: boolean;
  isExpired: boolean;
  isPreviousOrderWeek: boolean;
  cart: ViewCartExtendedDto;
  storeStatus: ViewStoreStatusDto;
  onCheckout: () => void;
}
function CartSummaryFooter({
  isAdmin,
  isRetail,
  isExpired,
  isPreviousOrderWeek,
  cart,
  storeStatus,
  onCheckout,
}: CartSummaryFooterProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const getCartExpiredMessage = () => {
    if (isExpired || isPreviousOrderWeek) return <ExpiredAlert />;
    return (
      <Alert severity="warning" sx={{ mt: 1 }}>
        <span>
          Checkout and submit order by{' '}
          {DateTime.fromISO(cart.expiresAt).toFormat('hh:mm a')} to prevent cart
          from expiring.
        </span>
      </Alert>
    );
  };

  const checkoutIsDisabled = () => {
    if (isExpired) return true;

    return false;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'rack-utilization-popover' : undefined;

  const racksFull =
    cart.racks.ceil().toFixed(3) === cart.racks.floor().toFixed(3);
  const rackSpaceAvailable = cart.racks.ceil().minus(cart.racks);

  const showOrderMinimumMsg = !(isAdmin || isRetail);

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          borderTop: '1px solid',
          fontWeight: 700,
        }}
      >
        <span>Product Total</span>
        <span>{usd(cart.productTotalBeforeDiscounts)}</span>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <span>Racks</span>
        <span>{cart.racks.toNearest(0.01).toString()}</span>
      </Box>
      {showOrderMinimumMsg && (
        <OrderMinimumMsg
          min={storeStatus?.minimumOrderValue}
          orderAmt={cart?.productTotalBeforeDiscounts}
        />
      )}
      <Button
        aria-describedby={id}
        fullWidth
        variant="contained"
        disabled={checkoutIsDisabled()}
        onClick={
          racksFull ? onCheckout : (event) => setAnchorEl(event.currentTarget)
        }
      >
        Checkout
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -0,
          horizontal: -10,
        }}
      >
        <Typography
          sx={{
            p: 2,
          }}
          component="div"
        >
          <h3>Rack Usage Alert</h3>
          <p>
            Hey there, it looks like you have a rack that isn't full. Each
            delivery rack has a fuel surcharge.
          </p>
          <p>You should fill up the rack to get your money's worth!</p>
          <p>
            <Typography
              sx={{ fontWeight: 'bold', display: 'inline' }}
              component="span"
            >
              Space Available:
            </Typography>
            {` ${rackSpaceAvailable.toFixed(2)} racks`}
          </p>
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={() => setAnchorEl(null)}>
              Keep Shopping
            </Button>
            <Button variant="contained" color="secondary" onClick={onCheckout}>
              Checkout
            </Button>
          </Stack>
        </Typography>
      </Popover>
      {getCartExpiredMessage()}
    </Box>
  );
}
