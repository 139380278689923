import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { plainToInstance } from 'class-transformer';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { VALIDATION_ERR_DELAY } from '../../config/AppConstants';
import { useAppContext } from '../../contexts/AppContext';
import { CreateChildProductOptionValueForExistingOptionAndValueDto } from '../../lib/api/product-option-values/dto/create-child-product-option-value-for-existing-option-and-value.dto';
import { CreateRootProductOptionValueForExistingOptionDto } from '../../lib/api/product-option-values/dto/create-root-product-option-value-for-existing-option.dto';
import { ViewProductOptionValueValuesDto } from '../../lib/api/product-option-values/dto/view-product-option-value.dto';
import {
  ViewChildProductOptionDto,
  ViewProductOptionDto,
} from '../../lib/api/product-options/dto/view-product-option.dto';
import { createProductOptionValue } from '../../lib/api/product-options/product-options.service';
import { ProductQueryKeys } from '../../lib/api/products/products.service';
import CustomForm, { onSuccessCallback } from '../ui/forms/CustomForm';
import FromSubmitButton from '../ui/forms/FormSubmitButton';
import RhfTagInput from '../ui/forms/RhfTagInput';
import RhfTextField from '../ui/forms/RhfTextField';
import useFormSubmitErrorAlert from '../ui/forms/useFormSubmitErrorAlert';

interface Props {
  option: ViewProductOptionDto | ViewChildProductOptionDto;
  parentOptionValue?: ViewProductOptionValueValuesDto;
  onSuccess?: onSuccessCallback;
}

export default function AddProductOptionValueForm({
  option,
  parentOptionValue,
  onSuccess,
}: Props) {
  const { handleShowMessage } = useAppContext();

  const queryClient = useQueryClient();
  const createMutation = useMutation(
    (
      createProductOptionValueDto:
        | CreateRootProductOptionValueForExistingOptionDto
        | CreateChildProductOptionValueForExistingOptionAndValueDto,
    ) => createProductOptionValue(option.id, createProductOptionValueDto),
  );

  const getChildrenDefault = () => {
    if (Object.hasOwn(option, 'children')) {
      return (
        (option as ViewProductOptionDto).children?.map((childOpt) => ({
          productOptionId: childOpt.id,
          values: [],
        })) || []
      );
    } else {
      return [];
    }
  };

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isSubmitted },
    control,
    setError,
    setFocus,
    watch,
  } = useForm<CreateRootProductOptionValueForExistingOptionDto>({
    resolver: classValidatorResolver(
      CreateRootProductOptionValueForExistingOptionDto,
    ),
    mode: 'onTouched',
    delayError: VALIDATION_ERR_DELAY,
    defaultValues: {
      value: '',
      children: getChildrenDefault(),
    },
  });

  const value = watch('value');

  const { fields } = useFieldArray({
    control,
    name: `children`,
  });

  useEffect(() => {
    setFocus('value');
  }, [setFocus]);

  const { clearFormSubmitError, processFormSubmitError, serverErrorMessage } =
    useFormSubmitErrorAlert<CreateRootProductOptionValueForExistingOptionDto>();

  const onSubmit: SubmitHandler<
    CreateRootProductOptionValueForExistingOptionDto
  > = async (formData: CreateRootProductOptionValueForExistingOptionDto) => {
    clearFormSubmitError();

    let createProductOptionValueDto;

    if (parentOptionValue) {
      //We have a prarentOptionValue, which means we are working with
      //a child option value
      createProductOptionValueDto = plainToInstance(
        CreateChildProductOptionValueForExistingOptionAndValueDto,
        { ...formData, parentId: parentOptionValue.id },
        {
          excludeExtraneousValues: true,
          exposeDefaultValues: false,
          exposeUnsetFields: false,
        },
      );
    } else {
      createProductOptionValueDto = plainToInstance(
        CreateRootProductOptionValueForExistingOptionDto,
        formData,
        {
          excludeExtraneousValues: true,
          exposeDefaultValues: false,
          exposeUnsetFields: false,
        },
      );
    }

    try {
      const createdProductOptionValue = await createMutation.mutateAsync(
        createProductOptionValueDto,
      );

      queryClient.invalidateQueries([ProductQueryKeys.findProductById]);
      queryClient.invalidateQueries([ProductQueryKeys.findProductVariants]);
      handleShowMessage(
        `Created ${option.name} '${createProductOptionValueDto.value}'`,
      );
      onSuccess?.(createdProductOptionValue);
    } catch (err: any) {
      processFormSubmitError(err, setError);
    }
  };

  return (
    <CustomForm
      serverErrorMessage={serverErrorMessage}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <RhfTextField
        control={control}
        name="value"
        label={`New ${option.name}`}
        required
      />
      {Object.hasOwn(option, 'children') && (
        <Grid container spacing={2} sx={{ pl: 5 }}>
          {fields.map((item, childIndex) => (
            <Grid key={item.id} container spacing={2} item>
              <Grid item xs={8}>
                <RhfTagInput
                  control={control}
                  name={`children.${childIndex}.values`}
                  defaultText={`${value} ${pluralize(
                    (option as ViewProductOptionDto).children[childIndex].name,
                  )} (Press Enter after each value)`}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      <FromSubmitButton
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
        isValid={isValid}
        text={`Add ${option.name}`}
      />
    </CustomForm>
  );
}
