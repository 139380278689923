import { AddressType } from '../../lib/api/addresses/enums/address-type.enum';
import CardItemTitle from '../ui/CardItemTitle';
import { AddressFragment } from './AddressContent';

interface Props {
  address: AddressFragment;
  customerName: string;
}

export default function AddressTitle({ address, customerName }: Props) {
  if (address.type === AddressType.billing)
    return <CardItemTitle text={`${customerName} (${address.code})`} />;

  return <CardItemTitle text={address.name} />;
}
