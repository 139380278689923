import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';
import OrdersTableIdCol from '../../../components/orders/OrdersTableIdCol';
import OrdersTableTypeCol from '../../../components/orders/OrdersTableTypeCol';
import FullScreenModal from '../../../components/ui/modal/FullScreenModal';
import useModal from '../../../components/ui/modal/useModal';
import PaginatedTable from '../../../components/ui/tables/paginated-table/PaginatedTable';
import { ViewOrderDto } from '../../../lib/api/orders/dto/view-order.dto';
import { OrderStatus } from '../../../lib/api/orders/enums/order-status.enum';
import { TableCol } from '../../../lib/util/table-util';
import OrderConfirmationPage from '../../orders/[id]/OrderConfirmationPage';
import {
  OrderActionsProps,
  OrderFullfillmentTableProps,
} from './shared.interfaces';
import { getOrderById } from '../../../lib/api/orders/orders.service';
import {
  createInvoiceAndLoadingListPdf,
  createInvoicePdf,
  createLoadingListPdf,
} from '../../../lib/common/pdf.service';

export default function OrdersStagingTable({
  apiRes,
  isFetching,
  setTableState,
  tableState,
  onRowClick,
  onChangeStatus,
}: OrderFullfillmentTableProps) {
  const { handleCloseModal, handleLaunchModal, modalOpen } = useModal();
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const columns: TableCol<ViewOrderDto>[] = [
    {
      id: 'id',
      label: 'ID',
      filterType: 'contains',
      render: (order: ViewOrderDto) => (
        <OrdersTableIdCol order={order} onClick={handleOrderIdClicked} />
      ),
      widthPct: 10,
      sortable: true,
      sortKey: 'createdAt',
    },
    {
      id: 'orderWeek',
      label: 'Order Week',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'deliveryRouteName',
      label: 'Route',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'billingCompanyName',
      label: 'Bill to',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'shippingCompanyName',
      label: 'Ship to',
      filterType: 'contains',
      widthPct: 15,
      headerCellSx: { minWidth: '115px' },
    },
    {
      id: 'status',
      label: 'Status',
      render: (o: ViewOrderDto) => (
        <>{`Staged on ${new Date(o.stagingAt).toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}`}</>
      ),
      widthPct: 15,
      headerCellSx: { minWidth: '150px' },
    },
    {
      id: 'type',
      label: 'Type',
      filterType: 'contains',
      render: (order: ViewOrderDto) => <OrdersTableTypeCol order={order} />,
      widthPct: 5,
      align: 'center',
    },
    {
      id: 'racks',
      label: 'Racks',
      render: (order: ViewOrderDto) => <>{order.racks.toFixed(2)}</>,
      widthPct: 5,
    },
    {
      // @ts-ignore
      id: 'actions',
      label: 'Action',
      render: (o: ViewOrderDto) => (
        <PickingOrderActions order={o} onChangeStatus={onChangeStatus} />
      ),
      widthPct: 5,
    },
  ];

  const handleOrderIdClicked = (e: any, order: ViewOrderDto) => {
    e.stopPropagation();
    setSelectedOrderId(order.id);
    handleLaunchModal();
  };

  return (
    <>
      <PaginatedTable<ViewOrderDto>
        columns={columns}
        apiRes={apiRes}
        isFetching={isFetching}
        setTableState={setTableState}
        tableState={tableState}
        onRowClick={onRowClick}
        paperElevation={0}
        hoverDisabled
        fullWidth
      />
      <FullScreenModal
        title="Order"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <Box sx={{ p: 3 }}>
          {selectedOrderId && (
            <OrderConfirmationPage
              orderId={selectedOrderId}
              closeModal={handleCloseModal}
            />
          )}
        </Box>
      </FullScreenModal>
    </>
  );
}

function PickingOrderActions({ order, onChangeStatus }: OrderActionsProps) {
  const handlePrintInvoice = async (orderId: string) => {
    const orderExtended = await getOrderById(orderId);
    createInvoicePdf(orderExtended);
  };

  const handlePrintLoadingList = async (orderId: string) => {
    const orderExtended = await getOrderById(orderId);
    createLoadingListPdf(orderExtended);
  };

  const handlePrintLoadingListAndInvoice = async (orderId: string) => {
    const orderExtended = await getOrderById(orderId);
    createInvoiceAndLoadingListPdf(orderExtended);
  };

  return (
    <Stack flexDirection="column" gap={1}>
      <Button
        variant="contained"
        size="small"
        sx={{ minWidth: '150px' }}
        onClick={(e) =>
          onChangeStatus(e, order.id, OrderStatus.selectedforpicking)
        }
      >
        Select for Picking
      </Button>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        sx={{ minWidth: '150px', color: 'secondary.light' }}
        onClick={(e) => onChangeStatus(e, order.id, OrderStatus.received)}
      >
        Send to Backlog
      </Button>
      <Button
        variant="outlined"
        size="small"
        sx={{ minWidth: '150px' }}
        onClick={() => handlePrintInvoice(order.id)}
      >
        Print Invoice
      </Button>
      <Button
        variant="outlined"
        size="small"
        sx={{ minWidth: '150px' }}
        onClick={() => handlePrintLoadingList(order.id)}
      >
        Print Loading List
      </Button>
      <Button
        variant="outlined"
        size="small"
        sx={{ minWidth: '150px' }}
        onClick={() => handlePrintLoadingListAndInvoice(order.id)}
      >
        Print Both
      </Button>
    </Stack>
  );
}
